import * as React from 'react';
import { Layout } from '../Layout';
import styles from './DraftDocumentSectionPage.module.css';
import { Button, Spinner } from '@fluentui/react-components';
import {
  ArrowRotateCounterclockwiseRegular,
  CheckmarkSquareRegular,
  Globe20Regular,
  Info16Regular,
} from '@fluentui/react-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  loadWordDocumentSections,
  scrollToParagraph,
  setIsAnalyzing,
} from '../../../../store/officeSlice';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from '@fluentui/react-components';
import { LeftArrowIcon } from '../../../../assets/LeftArrowIcon';

export const DraftDocumentSectionPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const documentSections = useSelector(
    (state: RootState) => state.office.documentSections
  );
  useEffect(() => {
    dispatch(loadWordDocumentSections());
  }, []);
  return (
    <Layout
      header={
        <div className={styles.toolbarContainer}>
          <div onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </div>

          <div className={styles.analyzeButtonContainer}>
            <Button
              shape="square"
              icon={<ArrowRotateCounterclockwiseRegular color="#0387F5" />}
              onClick={() => navigate('/review')}
            >
              <span style={{ color: '#0387F5' }}>Review</span>
            </Button>

            <Button
              shape="square"
              onClick={() => navigate('/emailDocument')}
              icon={<CheckmarkSquareRegular />}
            ></Button>
          </div>
        </div>
      }
      footer={<></>}
    >
      <div>
        <div className={styles.makeGlobalChangeContainer}>
          <Button
            icon={<Globe20Regular />}
            appearance="primary"
            onClick={() => {
              dispatch(setIsAnalyzing(false));
              navigate('/globalChanges');
            }}
          >
            Global changes
          </Button>
          <Info16Regular />
        </div>
      </div>

      <Accordion collapsible>
        {documentSections &&
          documentSections.map((section, idx) => (
            <AccordionItem
              className={styles.section}
              key={idx}
              value={idx}
              onClick={() => {
                dispatch(scrollToParagraph(section.heading));
              }}
            >
              <AccordionHeader expandIconPosition="end">
                <div className={styles.sectionHeader}>{section.heading}</div>
              </AccordionHeader>
              <AccordionPanel>
                {section.paragraphs.map((paragraph, idx) => (
                  <p key={`${idx}`}>{paragraph.text}</p>
                ))}

                <div className={styles.sectionHeader}>Commentary</div>
                <div className={styles.commentary}>
                  {section.isWaitingForCommentary && <Spinner size="huge" />}
                  {!section.isWaitingForCommentary && <p>{section.summary}</p>}
                </div>

                {/*<div className={styles.sectionHeader}>Modify Clause:</div>*/}
                {/*<div className={styles.requestContainer}>*/}
                {/*  <textarea*/}
                {/*    placeholder="Add a legal reference"*/}
                {/*    value={request}*/}
                {/*    onChange={e => setRequest(e.target.value)}*/}
                {/*  ></textarea>*/}
                {/*  <div className={styles.sendIcon}>*/}
                {/*    <ArrowUpIcon />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Layout>
  );
};
