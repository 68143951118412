import * as React from "react";

interface AcceptIconProps {
  className?: string;
}
export const AcceptIcon: React.FC<AcceptIconProps> = (props) => {
  return (
    <svg className={props.className} width="20px" height="20px" viewBox="0 0 28 28" fill='var(--acceptable)' xmlns="http://www.w3.org/2000/svg">
      <path d="M6.65263 14.0304C6.29251 13.6703 6.29251 13.0864 6.65263 12.7263C7.01276 12.3662 7.59663 12.3662 7.95676 12.7263L11.6602 16.4297L19.438 8.65183C19.7981 8.29171 20.382 8.29171 20.7421 8.65183C21.1023 9.01195 21.1023 9.59583 20.7421 9.95596L12.3667 18.3314C11.9762 18.7219 11.343 18.7219 10.9525 18.3314L6.65263 14.0304Z" />
      <path clip-rule="evenodd" d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1ZM3 14C3 7.92487 7.92487 3 14 3C20.0751 3 25 7.92487 25 14C25 20.0751 20.0751 25 14 25C7.92487 25 3 20.0751 3 14Z"  fill-rule="evenodd"/>
    </svg>
  );
};

