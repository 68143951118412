import * as React from 'react';
import { Layout } from './Layout';
import { GlobalChange } from '../reviewComponents/GlobalChange';
import styles from './GlobalChangePage.module.css';
import { LeftArrowIcon } from '../../../assets/LeftArrowIcon';
import { useNavigate } from 'react-router-dom';

export const GlobalChangePage = () => {
  const navigate = useNavigate();
  return (
    <Layout
      header={
        <div className={styles.toolbarContainer}>
          <div onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </div>
        </div>
      }
      footer={<></>}
    >
      <GlobalChange />
    </Layout>
  );
};
