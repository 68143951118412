export enum ActionEnum {
  FeedbackGetRequest = 'FeedbackGetRequest',
}

export interface BaseRequest {
  action: ActionEnum;
}

export interface BaseResponse {
  action: ActionEnum;
  errorMessage?: string | null;
}
