import {
  makeStyles,
  Image,
  Input,
  Spinner,
  shorthands,
} from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import {
  ActivityType,
  DocumentType,
  extractMatterName,
  formatUserActivityDate,
  openPDF,
  openWord,
} from '../../helper/home';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  GetUserActivity,
  SearchUserActivityFn,
  setLoadingSearch,
  setSearchUserActivities,
} from '../../store/activitySlice';
import Loading from '../shared/loading/Loading';
//import ChooseAttachement from '../features/review/components/ChooseAttachement';
import { setActionsTab, setCurrentTab } from '../../store/officeSlice';
import {
  ActionsTabs,
  HomeTabs,
  ReviewSteps,
} from '../features/review/components/const';
import {
  setChatMessages,
  setReviewStepNumber,
  setViewHeader,
} from '../../store/reviewSlice';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const HomeTab = () => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const userActivity = useSelector(
    (state: RootState) => state.activity.userActivity
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const isEmptyDocument = useSelector(
    (state: RootState) => state.office.isEmptyDocument
  );
  const loadingSearch2 = useSelector(
    (state: RootState) => state.activity.loadingSearch
  );
  const searchUserActivities = useSelector(
    (state: RootState) => state.activity.searchUserActivities
  );
  const displayDraftFlagValue = useSelector(
    (state: RootState) => state.featureFlag.displayDraftFlagValue
  );
  // const [isChooseAttch, setIsChooseAttach] = useState(false);
  const [popupWindow, setPopupWindow] = React.useState<Window | null>(null);
  const isMacos = navigator.userAgent.includes('Mac');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const handleClick = (e: React.MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log(value, 'value ');
    setSearchTerm(value);
    if (!value || value.length == 0) {
      setShowDropdown(false);
      dispatch(setSearchUserActivities([]));
    } else {
      setShowDropdown(true);
    }
  };
  const handleDisplayPopupWindow = (summary: string) => {
    // Close existing popup if open
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }

    // Open new popup window
    const newWindow = window.open(
      '',
      'SummaryPopup',
      'width=800,height=600,resizable=yes,scrollbars=yes'
    );

    if (newWindow) {
      setPopupWindow(newWindow);

      // Write initial content to the new window
      newWindow.document.write(`
        <html>
          <head>
            <title>Matter History</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              pre {
                background-color: #f5f5f5;
                padding: 1em;
                overflow-x: auto;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      // Render the ReactMarkdown in the popup
      ReactDOM.render(
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({
              node,
              inline,
              className,
              children,
              ...props
            }: {
              node?: any;
              inline?: boolean;
              className?: string;
              children: React.ReactNode;
            }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                  loading={() => <div>Loading syntax...</div>}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Custom rendering for tables to ensure they're responsive
            table: ({ children, ...props }) => (
              <div style={{ overflowX: 'auto' }}>
                <table {...props}>{children}</table>
              </div>
            ),
          }}
        >
          {summary}
        </ReactMarkdown>,
        newWindow.document.getElementById('root')
      );

      // Add event listener to close React app when window closes
      newWindow.addEventListener('unload', () => {
        ReactDOM.unmountComponentAtNode(
          newWindow.document.getElementById('root')
        );
      });
    } else {
      console.error(
        'Failed to open popup window. It may have been blocked by the browser.'
      );
    }
  };
  //-----------------------------------------------------
  useEffect(() => {
    if (isConnected) dispatch(GetUserActivity({}));
  }, [isConnected]);
  useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    };
  }, [popupWindow]);
  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(
        SearchUserActivityFn({
          message: debouncedSearchTerm,
        })
      );
      dispatch(setLoadingSearch(true));
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    dispatch(setViewHeader(true));
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //------------------------------------------
  /*   if (isChooseAttch)
    return <ChooseAttachement setIsChooseAttach={setIsChooseAttach} />; */
  if (!userActivity) return <Loading />;
  return (
    <div onClick={handleClick}>
      {userActivity &&
        userActivity.filter(u => u.activity_type === ActivityType.matterReview)
          .length === 0 && (
          <div className={styles.noActCnt}>
            <div>
              <Image src="/assets/no_activity.png" alt="" />
            </div>
            <div className={styles.noActHead}>
              You have no recent activities
            </div>
          </div>
        )}
      {userActivity &&
        userActivity.filter(u => u.activity_type === ActivityType.matterReview)
          .length > 0 && (
          <div>
            <div className={styles.actHeader}>
              <div className={styles.actHeaderTitle}>Recent</div>
              <Input
                placeholder="Search"
                className={styles.searchInput}
                contentAfter={
                  <Search24Regular className={styles.searchInputIcon} />
                }
                value={searchTerm}
                ref={searchInputRef}
                onChange={handleInputChange}
                onClick={() => setShowDropdown(true)}
              />
              {loadingSearch2 == true && (
                <div className={styles.searchResults}>
                  <div className={styles.loadingText}>
                    <Spinner size="small" />
                  </div>
                </div>
              )}
              {showDropdown && loadingSearch2 === 'no-result' && (
                <div className={styles.searchResults}>
                  <div className={styles.loadingText}>no results </div>
                </div>
              )}
              {showDropdown &&
                searchUserActivities.length > 0 &&
                searchUserActivities.filter(itemA => {
                  if (itemA.document_id) {
                    return !userActivity?.some(
                      itemB => itemB.document_id === itemA.document_id
                    );
                  } else {
                    return !userActivity?.some(
                      itemB => itemB.conversation_id === itemA.conversation_id
                    );
                  }
                }).length == 0 && (
                  <div className={styles.searchResults}>
                    <div className={styles.loadingText}>no results </div>
                  </div>
                )}
              {showDropdown && loadingSearch2 === false && showDropdown && (
                <div className={styles.searchResults} ref={containerRef}>
                  {searchUserActivities
                    .filter(itemA => {
                      if (itemA.document_id) {
                        return !userActivity?.some(
                          itemB => itemB.document_id === itemA.document_id
                        );
                      } else {
                        return !userActivity?.some(
                          itemB =>
                            itemB.conversation_id === itemA.conversation_id
                        );
                      }
                    })
                    .filter(
                      act => act.activity_type === ActivityType.matterReview
                    )
                    .map(act => {
                      return (
                        <div
                          className={styles.tableRow}
                          key={act.id}
                          onClick={() => {
                            if (
                              act.activity_type == ActivityType.matterReview
                            ) {
                              handleDisplayPopupWindow(act.matter_summary);
                            } else {
                              if (act.document_path) {
                                if (act.document_name?.includes('.pdf')) {
                                  openPDF(act.document_path);
                                } else {
                                  openWord(act.document_path, isMacos);
                                }
                              }
                            }
                          }}
                        >
                          <div className={styles.rowLeftSide}>
                            {getFileIcon(
                              act.activity_type as ActivityType,
                              DocumentType.Word,
                              styles
                            )}
                            <div>
                              <div className={styles.rowName}>
                                {act.matter_summary
                                  ? extractMatterName(act.matter_summary)
                                  : act.document_name}
                              </div>
                              <div className={styles.rowDate}>
                                {formatUserActivityDate(act.activity_date)}
                              </div>
                            </div>
                          </div>
                          <div className={styles.actType}>
                            {actTypeObject[act.activity_type]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            <div className={styles.tableHeader}>
              <div className={styles.cellHeader}></div>
              <div className={styles.cellHeader}></div>
            </div>
            {userActivity
              ?.filter(act => {
                if (displayDraftFlagValue) {
                  return true;
                } else {
                  if (act.activity_type == ActivityType.docDraft) {
                    return false;
                  } else {
                    return true;
                  }
                }
              })
              .filter(act => act.activity_type === ActivityType.matterReview)
              .map(act => {
                return (
                  <div
                    className={styles.tableRow}
                    key={act.id}
                    style={{
                      marginRight:
                        act.activity_type == ActivityType.matterReview
                          ? '2vw'
                          : undefined,
                    }}
                  >
                    <div
                      className={styles.rowLeftSide}
                      onClick={() => {
                        if (act.activity_type == ActivityType.matterReview) {
                          handleDisplayPopupWindow(act.matter_summary);
                        } else {
                          if (act.document_path) {
                            if (act.document_name?.includes('.pdf')) {
                              openPDF(act.document_path);
                            } else {
                              openWord(act.document_path, isMacos);
                            }
                          }
                        }
                      }}
                    >
                      {getFileIcon(
                        act.activity_type as ActivityType,
                        DocumentType.Word,
                        styles
                      )}
                      <div>
                        <div className={styles.rowName}>
                          {act.matter_summary
                            ? extractMatterName(act.matter_summary)
                            : act.document_name}
                        </div>
                        <div className={styles.rowDate}>
                          {formatUserActivityDate(act.activity_date)}
                        </div>
                      </div>
                    </div>
                    <div className={styles.actType}>
                      {act.activity_type === ActivityType.matterReview ? (
                        <div className={styles.matterBtns}>
                          {/*  {!isEmptyDocument && (
                            <button
                              className={styles.matterBtn}
                              style={{ height: 'auto' }}
                              onClick={() => {
                                dispatch(setCurrentTab(HomeTabs.Actions));
                                dispatch(setActionsTab(ActionsTabs.review));
                                dispatch(setChatMessages([]));
                                dispatch(setReviewStepNumber(ReviewSteps.chat));
                              }}
                            >
                              Review
                            </button>
                          )} */}
                          {isEmptyDocument &&
                            displayDraftFlagValue &&
                            false && (
                              <button
                                className={styles.matterBtn}
                                onClick={() => {
                                  // setIsChooseAttach(true);
                                  dispatch(setCurrentTab(HomeTabs.Actions));
                                  dispatch(setActionsTab(ActionsTabs.draft));
                                }}
                              >
                                Draft
                              </button>
                            )}
                        </div>
                      ) : (
                        actTypeObject[act.activity_type]
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
    </div>
  );
};

export default HomeTab;

const useStyles = makeStyles({
  loadingCnt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tableRow: {
    display: 'flex',
    ...shorthands.padding('6px'),
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginLeft: '5vw',
    marginRight: '4vw',
    ...shorthands.border('0.74px', 'solid', 'white'),
    borderRightWidth: '0px', // Change background color on hover

    '&:hover': {
      ...shorthands.border('0.74px', 'solid', '#f0f0f0'), // Change background color on hover
      cursor: 'pointer', // Change cursor to pointer on hover
      borderRightWidth: '0px', // Change background color on hover
    },
  },
  rowName: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#3C3C3C',
    whiteSpace: 'nowrap' /* Prevents text from wrapping to a new line */,
    ...shorthands.overflow(
      'hidden'
    ) /* Ensures content that overflows the element is hidden */,
    textOverflow:
      'ellipsis' /* Adds '...' at the point where the text is cut off */,
    width: '185px',
  },
  rowImg: { width: '16px', height: '17px', marginTop: '5px' },
  actType: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#3C3C3C',
  },
  rowDate: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#6E6E6E',
  },
  rowLeftSide: {
    display: 'flex',
    ...shorthands.gap('6px'),
    alignItems: 'start',
  },
  noActCnt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '150px',
    ...shorthands.gap('25px'),
  },
  noActHead: {
    fontFamily: 'Arial',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#777777',
  },
  actHeader: {
    display: 'flex',
    ...shorthands.padding('12px'),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    display: 'flex',
    ...shorthands.padding('8px'),
    paddingLeft: '5vw',
    paddingRight: '5vw',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ECECEC',
    ...shorthands.borderRadius('3px'),
    marginBottom: '2vh',
    minHeight: '20px',
  },
  cellHeader: {
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#000000',
  },
  actHeaderTitle: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#3B3B3B',
  },
  searchInput: {
    width: '145px',
    height: '25px',
    ...shorthands.borderRadius('5px'),
    fontSize: '13px',
  },
  searchInputIcon: {
    width: '14px',
    height: '13px',
  },
  searchResults: {
    position: 'absolute',
    top: '140px',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    ...shorthands.border('1px', 'solid', '#ccc'),
    ...shorthands.borderRadius('8px'),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    maxHeight: '60vh',
    overflowY: 'auto',
  },
  loadingText: {
    ...shorthands.padding('11px'),
    opacity: '80%',
    textAlign: 'center',
  },
  matterBtns: {
    display: 'flex',
    ...shorthands.gap('2.5px'),
  },
  matterBtn: {
    height: '20px',
    ...shorthands.padding('3px', '5px'),
    ...shorthands.border('none'),
    textAlign: 'center',
    fontSize: '10px',
    fontFamily: 'Arial',
    backgroundColor: '#04142D',
    color: 'white',
    ...shorthands.borderRadius('2px'),
    cursor: 'pointer',
    minWidth: '55px',
    '&:hover': {
      //backgroundColor: 'lightgrey',
      scale: '1.01',
      opacity: '.85',
    },
  },
});

const getFileIcon = (
  actType: ActivityType,
  docType: DocumentType,
  styles: Record<any, any>
) => {
  if (actType == ActivityType.matterReview) {
    return (
      <Image
        className={styles.rowImg}
        style={{ marginTop: '2px' }}
        src={'assets/matterReview.png'}
        alt="Word Icon"
      />
    );
  } else {
    switch (docType) {
      case DocumentType.Word:
        return (
          <Image
            className={styles.rowImg}
            src={'assets/word_icon.png'}
            alt="Word Icon"
          />
        );

      case DocumentType.PDF:
        return (
          <Image
            className={styles.rowImg}
            src={'assets/pdf_icon.png'}
            alt="PDF Icon"
          />
        );
    }
  }
};

const actTypeObject = {
  [ActivityType.docRevew]: 'Review',
  [ActivityType.matterReview]: 'Matter History',
  [ActivityType.docDraft]: 'Draft',
  [ActivityType.docMemo]: 'Memo',
  [ActivityType.docProposal]: 'Proposal',
};
