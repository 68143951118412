import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlobServiceClient } from '@azure/storage-blob';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { sendMessage } from './socketSlice';
import { RootState } from './store';

import {
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  DocumentType,
  DraftDocumentRequest,
  ListTemplatesRequest,
  TemplateDocument,
} from '../models/draft';
import { RequestEnum } from '../models/base';
import {
  ChatMessage,
  ChatWordRequest,
  ChatWordResponse,
  ChatWordResponseChunk,
  isChatWordResponseChunk,
  MessageRole,
} from '../models/chat';

import {
  getDocumentContentAsText,
  replaceDocumentContent,
} from '../helper/office';
import { tokenHelper } from '../helper/tokenHelper';
import { getFileType } from '../helper/misc';

interface DraftState {
  draftText: string;
  hasGeneratedDraft: boolean;
  isLoadingDraft: boolean;
  draftError: Error;
  templateDocuments: TemplateDocument[];
  // base doc upload
  uploadedDocument: TemplateDocument;
  isLoadingUploadedDocument: boolean;
  isUploadedDocumentInQueue: boolean;
  hasUploadedDocument: boolean;
  uploadError: Error
  // template upload
  templateId?: string;
  templateTitle?: string;
  templateType?: string;
  isLoadingCreateTemplate: boolean;
  isLoadingDeleteTemplate: boolean;
  isLoadingListTemplate: boolean;
  // chat
  chatMessages: ChatMessage[];
  chatThreadId?: string;
}

const initialState: DraftState = {
  draftText: '',
  hasGeneratedDraft: false,
  isLoadingDraft: false,
  draftError: undefined,
  templateDocuments: [],
  // base doc
  uploadedDocument: undefined,
  isLoadingUploadedDocument: false,
  isUploadedDocumentInQueue: false,
  hasUploadedDocument: false,
  uploadError: undefined,
  // template upload
  isLoadingCreateTemplate: false,
  isLoadingListTemplate: false,
  // chat
  chatMessages: [],
  isLoadingDeleteTemplate: false
};

const getAzureBlobClient = (): Promise<BlobServiceClient> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/sas-token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const url = response.data.url;
        console.log(url);
        resolve(new BlobServiceClient(url));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};

const getUser = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        console.log('Acquired token:', token);
        const response = await axios.get(
          `${process.env.BACKEND_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('User data:', response.data);
        resolve(response.data);
      } catch (error) {
        console.error('Error getting user:', error);
        reject(error);
      }
    });
  });
};

export interface SendDraftRequestPayload {
  prompt: string;
  template?: TemplateDocument;
  draftRole?: string;
}

export const sendDraftRequest = createAsyncThunk(
  'draft/sendDraftRequest',
  async (
    { prompt, template, draftRole }: SendDraftRequestPayload,
    { dispatch }
  ) => {
    console.log('Sending draft request with: ', prompt, template);

    dispatch(setIsLoadingDraft(true));

    console.log('Sending draft request - prompt:', prompt);
    console.log('Sending draft request - template:', template);

    const message: DraftDocumentRequest = {
      action: RequestEnum.DraftDocumentRequest,
      query: prompt,
      template_id: template?.id,
      draft_role: draftRole,
    };

    console.log('Created a message to send', message);

    dispatch(sendMessage(message));
  }
);

export const uploadDocument = createAsyncThunk<TemplateDocument, File, { rejectValue: string }>(
  'draft/uploadDocument',
  async (file: File, { dispatch, rejectWithValue }) => {
    try {
      const blobServiceClient = await getAzureBlobClient();
      dispatch(setIsLoadingUploadedDocument(true));

      let documentUuid = uuidv4();
      let user = await getUser();
      console.log(documentUuid, ">>>>>>>>>>>>")
      const containerClient =
        blobServiceClient.getContainerClient('templates');

      //upload the bye array to the container
      const blockBlobClient = containerClient.getBlockBlobClient(
        `${user.id}/${documentUuid}/${file.name}`
      );
      const fileData = await file.arrayBuffer();
      const response = await blockBlobClient.uploadData(new Uint8Array(fileData));


      if (response._response.status === 201) {
        const urlWithoutSAS = blockBlobClient.url.split('?')[0];
        const uploadedDocument: TemplateDocument = {
          id: documentUuid,
          name: file.name,
          path: urlWithoutSAS,
          type: getFileType(file),
          text: ""
        };
        dispatch(setUploadedDocument(uploadedDocument))
        return uploadedDocument;
      } else {
        throw new Error('Failed to upload document');
      }
    } catch (error) {
      dispatch(setUploadError(error));
      console.error(error)
      return rejectWithValue(error.message);
    }
  }
);

export const sendTemplateListRequest = createAsyncThunk(
  'draft/sendTemplateListRequest',
  async (_, { dispatch }) => {
    const message: ListTemplatesRequest = {
      action: RequestEnum.ListTemplatesRequest,
    };

    console.log('Sending a request for templates', message);

    dispatch(sendMessage(message));
    dispatch(setIsLoadingListTemplate(true))
  }
);

export const sendCreateTemplateRequest = createAsyncThunk(
  'draft/sendCreateTemplateRequest',
  async ({ name, type, path }: TemplateDocument, { dispatch }) => {

    const message: CreateTemplateRequest = {
      action: RequestEnum.CreateTemplateRequest,
      document: {
        text: null,
        name: name,
        type: type,
        path: path
      },
    };

    console.log('Sending create user document request:', message);

    dispatch(setIsUploadedDocumentInQueue(true));
    dispatch(sendMessage(message));
  }
);

export const sendDeleteTemplateRequest = createAsyncThunk(
  'draft/sendDeleteTemplateRequest',
  async (id: string, { dispatch }) => {
    const message: DeleteTemplateRequest = {
      action: RequestEnum.DeleteTemplateRequest,
      template_id: id
    };

    console.log('Sending delete template request:', message);

    dispatch(setIsLoadingDeleteTemplate(true));
    dispatch(sendMessage(message));
  }
);

export const handleCreateTemplateResponse = createAsyncThunk(
  'draft/handleCreateTemplateResponse',
  async (response: CreateTemplateResponse, { dispatch }) => {
    console.log('Received user document response:', response);

    dispatch(setUploadedDocumentId(response.document_id));
    dispatch(setIsLoadingUploadedDocument(false));
    dispatch(setHasUploadedDocument(true));
    dispatch(setUploadedDocument(null));
    dispatch(sendTemplateListRequest());
  }
);

export const handleDeleteTemplateResponse = createAsyncThunk(
  'draft/handleDeleteTemplateResponse',
  async (response: DeleteTemplateResponse, { dispatch, getState }) => {
    console.log('Received user document response:', response);
    const state = getState() as RootState;
    const { templateDocuments } = state.draft;
    dispatch(setTemplateDocuments(templateDocuments.filter(template => template.id !== response.template_id)))
    dispatch(setIsLoadingDeleteTemplate(false))
  }
);

export const addAttachMessage = createAsyncThunk(
  'draft/addAttachMessage',
  async (newMessage: ChatMessage, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { chatMessages } = state.draft;
    const { chatThreadId } = state.draft;

    const updatedMessages = [
      ...chatMessages,
      newMessage,
    ];
    dispatch(setChatMessages(updatedMessages));
  }
);

export const sendChatMessage = createAsyncThunk(
  'draft/sendChatMessage',
  async (newMessage: ChatMessage, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { chatMessages } = state.draft;
    const { chatThreadId } = state.draft;

    const updatedMessages = [
      ...chatMessages,
      newMessage,
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
        attached : "",
      },
    ];

    dispatch(setChatMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ChatWordRequest = {
      action: RequestEnum.ChatWordRequest,
      thread_id: chatThreadId,
      messages: [...chatMessages, newMessage],
      open_document_body: documentText,
    };

    console.log('Sending a new chat message:', newMessage);

    dispatch(sendMessage(chatMessageRequest));
  }
);

export const handleChatMessageResponse = createAsyncThunk(
  'draft/handleChatMessageResponse',
  async (
    response: ChatWordResponse | ChatWordResponseChunk,
    { dispatch, getState }
  ) => {
    const { draft } = getState() as RootState;
    const { chatMessages } = draft;
    const isChunk = isChatWordResponseChunk(response);
    const newMessage: ChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk,
      attached: ""
    };

    const shouldReplaceLastMessage =
      chatMessages.length > 0 && chatMessages[chatMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatMessages.slice(0, -1), newMessage]
      : [...chatMessages, newMessage];

    dispatch(setChatMessages(updatedMessages));
    dispatch(setChatThreadId(response.thread_id));
  }
);

export const displayDraftResponse = createAsyncThunk(
  'draft/displayDraftResponse',
  async (draftText: string, { dispatch }) => {
    if (!draftText) {
      console.log('No content to insert');
      dispatch(setDraftError(new Error('No content to insert')));
    } else {
      try {
        await replaceDocumentContent(draftText);
        dispatch(setHasGeneratedDraft(true));
      } catch (error) {
        dispatch(setDraftError(new Error('Failed to insert text')));
      }
    }

    dispatch(setDraftText(draftText));
    dispatch(setIsLoadingDraft(false));
  }
);

export const displayDraftChunk = createAsyncThunk(
  'draft/displayDraftChunk',
  async (draftText: string, { dispatch }) => {
    if (draftText) {
      try {
        await replaceDocumentContent(draftText);
      } catch (error) {
        dispatch(setDraftError(new Error('Failed to insert text')));
      }
    }
  }
);

export const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    setDraftText: (state, action: PayloadAction<string>) => {
      state.draftText = action.payload;
    },
    setHasGeneratedDraft: (state, action: PayloadAction<boolean>) => {
      state.hasGeneratedDraft = action.payload;
    },
    setIsLoadingDraft: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDraft = action.payload;
    },
    setDraftError: (state, action: PayloadAction<Error>) => {
      state.draftError = action.payload;
    },
    setTemplateDocuments: (state, action: PayloadAction<TemplateDocument[]>) => {
      state.templateDocuments = action.payload;
    },
    // BASE DOCUMENT UPLOAD
    setUploadedDocumentId: (state, action: PayloadAction<string>) => {
      state.uploadedDocument.id = action.payload;
    },
    setUploadedDocument: (state, action: PayloadAction<TemplateDocument>) => {
      state.uploadedDocument = action.payload;
    },
    setHasUploadedDocument: (state, action: PayloadAction<boolean>) => {
      state.hasUploadedDocument = action.payload;
    },
    setIsUploadedDocumentInQueue: (state, action: PayloadAction<boolean>) => {
      state.isUploadedDocumentInQueue = action.payload;
    },
    setIsLoadingUploadedDocument: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUploadedDocument = action.payload;
    },
    setUploadError: (state, action: PayloadAction<Error>) => {
      state.uploadError = action.payload;
    },
    // TEMPLATE UPLOAD
    setTemplateId: (state, action: PayloadAction<string>) => {
      state.templateId = action.payload;
    },
    setTemplateTitle: (state, action: PayloadAction<string>) => {
      state.templateTitle = action.payload;
    },
    setTemplateType: (state, action: PayloadAction<string>) => {
      state.templateType = action.payload;
    },
    setIsLoadingCreateTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCreateTemplate = action.payload;
    },
    setIsLoadingDeleteTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDeleteTemplate = action.payload;
    },
    setIsLoadingListTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingListTemplate = action.payload;
    },
    // CHAT
    setChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.chatMessages = action.payload;
    },
    setChatThreadId: (state, action: PayloadAction<string>) => {
      state.chatThreadId = action.payload;
    },
  },
});

export const {
  setDraftText,
  setHasGeneratedDraft,
  setIsLoadingDraft,
  setDraftError,
  setTemplateDocuments,
  // base doc
  setUploadedDocumentId,
  setUploadedDocument,
  setIsUploadedDocumentInQueue,
  setHasUploadedDocument,
  setIsLoadingUploadedDocument,
  setUploadError,
  // upload template
  setTemplateId,
  setIsLoadingCreateTemplate,
  // chat
  setChatMessages,
  setChatThreadId,
  setIsLoadingDeleteTemplate,
  setIsLoadingListTemplate
} = draftSlice.actions;

export default draftSlice.reducer;
