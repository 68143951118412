import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Spinner } from '@fluentui/react-components';
import { DocumentAdd16Regular, Sparkle16Filled } from '@fluentui/react-icons';

import { Layout } from './Layout';

import {
  checkDocumentHasContent,
  setIsAnalyzing,
  setPreventAnalyzing,
} from '../../../store/officeSlice';
import { AppDispatch, RootState } from '../../../store/store';
import { initApp } from '../../../store/rootReducer';
import {
  setDraftError,
  setHasGeneratedDraft,
  setHasUploadedDocument,
  setIsUploadedDocumentInQueue,
  setIsLoadingUploadedDocument,
  setIsLoadingDraft,
} from '../../../store/draftSlice';

import styles from './HomePage.module.css';

export const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const suggestions = useSelector(
    (state: RootState) => state.office.suggestions
  );

  const isEmptyDocument = useSelector(
    (state: RootState) => state.office.isEmptyDocument
  );

  useEffect(() => {
    dispatch(initApp());
    dispatch(checkDocumentHasContent());
  }, [dispatch]);

  useEffect(() => {
    if (isEmptyDocument !== undefined && isEmptyDocument) {
      // redirect to draft
      dispatch(setIsLoadingDraft(false));
      dispatch(setIsUploadedDocumentInQueue(false));
      dispatch(setHasUploadedDocument(false));
      dispatch(setIsLoadingUploadedDocument(false));
      dispatch(setHasGeneratedDraft(false));
      dispatch(setDraftError(undefined));
      setTimeout(() => navigate('draft'), 1000);
    }
    if (isEmptyDocument !== undefined && !isEmptyDocument) {
      // redirect to review
      setTimeout(() => navigate('review'), 1000);
    }
  }, [isEmptyDocument]);
  // check of the document to see if the document is new
  return (
    <Layout header={undefined} footer={undefined}>
      <Spinner size="large" label="Getting document ready" />
      {/*<div className={styles.buttonWrapper}>*/}
      {/*  <Button*/}
      {/*    className={styles.button}*/}
      {/*    onClick={() => {*/}
      {/*      console.log('suggestions', suggestions.length);*/}
      {/*      if (suggestions.length > 0) {*/}
      {/*        dispatch(setIsAnalyzing(false));*/}
      {/*        dispatch(setPreventAnalyzing(true));*/}
      {/*      } else {*/}
      {/*        dispatch(setIsAnalyzing(true));*/}
      {/*      }*/}
      {/*      return navigate('review');*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div className={styles.titleContainer}>*/}
      {/*      <Sparkle16Filled className={styles.titleIcon} />*/}
      {/*      <div>Review a Document</div>*/}
      {/*    </div>*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    className={styles.button}*/}
      {/*    onClick={() => {*/}
      {/*      dispatch(setIsLoadingDraft(false));*/}
      {/*      dispatch(setIsBaseDocumentInQueue(false));*/}
      {/*      dispatch(setHasUploadedBaseDocument(false));*/}
      {/*      dispatch(setIsLoadingBaseDocument(false));*/}
      {/*      dispatch(setHasGeneratedDraft(false));*/}
      {/*      dispatch(setDraftError(undefined));*/}
      {/*      return navigate('draft');*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div className={styles.titleContainer}>*/}
      {/*      <DocumentAdd16Regular className={styles.titleIcon} />*/}
      {/*      <div>Draft a Document</div>*/}
      {/*    </div>*/}
      {/*  </Button>*/}
      {/* <Button
          className={styles.button}
          onClick={() => {
            navigate('documentSection');
          }}
        >
          <div className={styles.titleContainer}>
            <DocumentAdd16Regular className={styles.titleIcon} />
            <div>View Document Sections</div>
          </div>
        </Button> */}
      {/*   </div>*/}
    </Layout>
  );
};
