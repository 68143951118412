// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CT2vspRGKNqkEbglQOZN {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  justify-content: center;\n  align-items: center;\n  font-weight: 400;\n  font-size: 0.75rem;\n  color: #626262;\n}\n.TeaYHNGKisSKEtJUy8PP {\n  display: flex;\n  flex-direction: row;\n  gap: 0.3rem;\n  justify-content: center;\n  align-items: center;\n  width: 90%;\n  margin: 0 0 0.5rem 0.5rem;\n}\n.SAnwlcBnVYVx1apgOfyz {\n  margin-right: 0.5rem;\n  background-color: #0387f5;\n}\n.RyZ0fAikgOo3UcS5jGBg {\n  margin-right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/reviewComponents/ReviewProgress.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,yBAAyB;AAC3B;AACA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  justify-content: center;\n  align-items: center;\n  font-weight: 400;\n  font-size: 0.75rem;\n  color: #626262;\n}\n.progressContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 0.3rem;\n  justify-content: center;\n  align-items: center;\n  width: 90%;\n  margin: 0 0 0.5rem 0.5rem;\n}\n.progressBar {\n  margin-right: 0.5rem;\n  background-color: #0387f5;\n}\n.percentage {\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CT2vspRGKNqkEbglQOZN",
	"progressContainer": "TeaYHNGKisSKEtJUy8PP",
	"progressBar": "SAnwlcBnVYVx1apgOfyz",
	"percentage": "RyZ0fAikgOo3UcS5jGBg"
};
export default ___CSS_LOADER_EXPORT___;
