import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './index.module.css';

import { Provider } from 'react-redux';
import store, { persister } from '../store/store';
import { DraftReviewPage } from './components/pages/DraftReviewPage';
import { GlobalChange } from './components/reviewComponents/GlobalChange';
import { Clauses } from './components/reviewComponents/Clauses';
import { ReviewList } from './components/reviewComponents/ReviewList';
import { HomePage } from './components/pages/HomePage';
import { DraftPage } from './components/pages/draft/DraftPage';
import { DraftDocumentSectionPage } from './components/pages/draft/DraftDocumentSectionPage';
import { GlobalChangePage } from './components/pages/GlobalChangePage';
import { EmailDocumentPage } from './components/pages/EmailDocumentPage';
import { DebugModePage } from './components/pages/DebugModePage';

const rootElement: HTMLElement = document.getElementById('container');
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(async () => {
  console.log('Rendering the React application...');
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <FluentProvider theme={webLightTheme}>
          <BrowserRouter>
            <Routes>
              <Route index path="/" element={<HomePage />} />
              <Route path="/draft" element={<DraftPage />} />
              <Route
                path="/documentSection"
                element={<DraftDocumentSectionPage />}
              />
              <Route path="/globalChanges" element={<GlobalChangePage />} />
              <Route path="/review" element={<DraftReviewPage />}>
                <Route index element={<ReviewList />} />
                <Route path="clauses" element={<Clauses />} />
                <Route path="globalChanges" element={<GlobalChange />} />
              </Route>
              <Route path="/emailDocument" element={<EmailDocumentPage />} />
              <Route path="/debug" element={<DebugModePage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </FluentProvider>
      </PersistGate>
    </Provider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    root.render(<NextApp />);
  });
}
