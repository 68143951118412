import { List } from "@fluentui/react";

export interface DocumentData {
    styles: any;
    content: any;
    templateBase64: any;
}

interface StyleFormat {
    font?: {
        name: string;
        size: number;
        bold: boolean;
        italic: boolean;
    };
    paragraph?: {
        alignment: 'CENTER' | 'LEFT' | 'JUSTIFIED' | 'RIGHT';
        space_before: number;
        space_after: number;
    };
}

interface Font {
    name: string;
    size: number;
}


export class DocumentService {
    private static NUMBERING = [];
    private static FONT: Font = { name: "Arial", size: 9 };
    private static SECTION_NUMBER: number = 0;
    private static LINE_HEIGHT_MULTIPLIER: number = 1.5;
    private static PARAGRAPH_SPACE_MULTIPLIER: number = 0.5;

    // Constants for better readability
    private static BASE_INDENT = 36;        // Base indentation
    private static LEVEL_INCREMENT = 36;    // Additional indent per level
    private static HANGING_RATIO = 0.5;     // Hanging indent ratio

    private static async docxToBase64(docxPath: string): Promise<string> {
        const response = await fetch(docxPath);
        const arrayBuffer = await response.arrayBuffer();
        const base64 = Buffer.from(arrayBuffer).toString('base64');
        return base64;
    }

    static async buildDocument(documentData: DocumentData): Promise<void> {
        try {
            await Word.run(async (context) => {
                console.log("Starting document build...");
                
                // Validate input data
                if (!documentData?.content || !documentData?.styles || !documentData?.templateBase64) {
                    throw new Error("Invalid document data structure");
                }
                
                // Clear existing content
                context.document.body.clear();
                await context.sync();
                console.log("Document cleared");

                // Load numbering template
                // await this.loadNumberingTemplate(context);

                // Apply styles first
                if (Object.keys(documentData.styles).length > 0) {
                    try {
                        await this.applyStyles(context, documentData.styles, documentData.templateBase64);
                        await context.sync();
                        console.log("Styles applied successfully");
                    } catch (err) {
                        console.error("Style application error:", err);
                        // Continue even if styles fail
                    }
                }

                // Add content
                if (documentData.content) {
                    try {
                        await this.addContent(context, documentData.content);
                        await context.sync();
                        console.log("Content added successfully");
                    } catch (err) {
                        console.error("Content addition error:", err);
                        throw err;
                    }
                }

                await context.sync();
                console.log("Document build completed");
            });
        } catch (error) {
            console.error('Error building document:', error);
            console.error('Document data:', JSON.stringify(documentData, null, 2));
            // throw error;
        }
        // customNumberingQanooni
    }
    
    private static async applyStyles(context: Word.RequestContext, styles: { 
        headings?: Record<string, StyleFormat>;
        normal?: StyleFormat;
        lists?: StyleFormat;
        recitals?: StyleFormat; }, templateBase64: string): Promise<void> {
        try {
            let fontName = 'Arial';
            let minfontSize = 9;
            let maxfontSize = 14;


            if (styles.headings) {
                const firstHeading = Object.keys(styles.headings)[0];
                fontName = styles.headings[firstHeading].font.name;
                // minfontSize = styles.headings['1'].font.size;
                maxfontSize = styles.headings[firstHeading].font.size;
                console.log("Font name:", fontName);
            }


            this.FONT.name = fontName;
            this.FONT.size = Math.max(minfontSize, Math.min(maxfontSize, styles.normal.font.size));
            // Insert numbering definition first
            const dummy = templateBase64
            // await this.insertNumberingDefinition(context, templateBase64);
            
            // Create base style first
            if (styles.normal) {
                let normalStyle;
                try {
                    normalStyle = context.document.getStyles().getByNameOrNullObject('CustomNormal');
                    await context.sync();
                    if (!normalStyle.isNullObject) {
                        normalStyle.delete();
                        await context.sync();
                    }
                    normalStyle = context.document.addStyle('CustomNormal', Word.StyleType.paragraph);
                } catch (error) {
                    normalStyle = context.document.addStyle('CustomNormal', Word.StyleType.paragraph);
                }
                await context.sync();
                
                if (styles.normal.font) {
                    normalStyle.font.name = fontName;  // styles.normal.font.name;
                    normalStyle.font.size = Math.max(minfontSize, Math.min(maxfontSize, styles.normal.font.size));
                    normalStyle.font.bold = styles.normal.font.bold;
                    normalStyle.font.italic = styles.normal.font.italic;
                    await context.sync();
                }
                if (styles.normal.paragraph) {
                    normalStyle.paragraphFormat.lineSpacing = styles.normal.font.size * this.LINE_HEIGHT_MULTIPLIER; // Set line height to double the font size
                    normalStyle.paragraphFormat.alignment = Word.Alignment.justified;
                        // styles.normal.paragraph.alignment === 'CENTER' ? Word.Alignment.centered : Word.Alignment.justified;
                    normalStyle.paragraphFormat.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER; //styles.normal.paragraph.space_after === 0 ? 120 : styles.normal.paragraph.space_after;
                    normalStyle.paragraphFormat.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER; //styles.normal.paragraph.space_before === 0 ? 120 : styles.normal.paragraph.space_before;
                    await context.sync();
                }
            }

            // Then create heading styles
            if (styles.headings) {
                for (const [level, style] of Object.entries(styles.headings)) {
                    try {
                        const styleName = `Custom${level.toUpperCase()}`;
                        let wordStyle;
                        try {
                            wordStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                            await context.sync();
                            if (!wordStyle.isNullObject) {
                                wordStyle.delete();
                                await context.sync();
                            }
                            wordStyle = context.document.addStyle(styleName, Word.StyleType.paragraph);
                        } catch (error) {
                            wordStyle = context.document.addStyle(styleName, Word.StyleType.paragraph);
                        }
                        await context.sync();
                        
                        if (style.font) {
                            wordStyle.font.name = fontName;  // style.font.name;
                            wordStyle.font.size = style.font.size;
                            wordStyle.font.bold = true; // style.font.bold;  TODO: make it dynamic
                            wordStyle.font.italic = style.font.italic;
                            await context.sync();
                        }
                        if (style.paragraph) {
                            wordStyle.paragraphFormat.lineSpacing = style.font.size * this.LINE_HEIGHT_MULTIPLIER;
                            if (styleName === 'CustomH3') {
                                wordStyle.paragraphFormat.alignment = Word.Alignment.justified;
                            } else {    
                                wordStyle.paragraphFormat.alignment = Word.Alignment.centered; // : Word.Alignment.justified;
                            }

                            wordStyle.paragraphFormat.spaceAfter = Math.max(this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER, style.paragraph.space_after);
                            wordStyle.paragraphFormat.spaceBefore = Math.max(this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER, style.paragraph.space_before);
                            await context.sync();
                        }
                    } catch (err) {
                        console.warn(`Failed to create style for ${level}:`, err);
                    }
                }
            }

            // Apply list formatting
            if (styles.lists) {
                for (const [level, style] of Object.entries(styles.lists)) {
                    const listStyleName = `CustomList${level}`;
                    try {
                        // Check if style exists and delete it
                        const existingStyle = context.document.getStyles().getByNameOrNullObject(listStyleName);
                        await context.sync();
                        if (!existingStyle.isNullObject) {
                            existingStyle.delete();
                            await context.sync();
                        }
                        
                        // Create new style
                        const listStyle = context.document.addStyle(listStyleName, Word.StyleType.paragraph);
                        await context.sync();

                        listStyle.baseStyle = "List Paragraph";
                        await context.sync();

                        // Apply font properties
                        if (style.font) {
                            listStyle.font.name = fontName;  // style.font.name;
                            listStyle.font.size = Math.max(minfontSize, Math.min(maxfontSize, style.font.size));
                            listStyle.font.bold = style.font.bold;
                            listStyle.font.italic = style.font.italic;
                            await context.sync();
                        }
                        // Apply paragraph properties
                        if (style.paragraph) {
                            listStyle.paragraphFormat.lineSpacing = style.font.size * this.LINE_HEIGHT_MULTIPLIER;
                            listStyle.paragraphFormat.alignment = Word.Alignment.justified;
                                // style.paragraph.alignment === 'LEFT' ? Word.Alignment.left : Word.Alignment.justified;
                            // listStyle.paragraphFormat.leftIndent = 18 * parseInt(level);  // Indent based on level
                            // listStyle.paragraphFormat.firstLineIndent = -9;
                            listStyle.paragraphFormat.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;  // style.paragraph.space_after || 0;
                            listStyle.paragraphFormat.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;  // style.paragraph.space_before || 0;
                            await context.sync();
                        }

                        if (style.numbering) {
                            
                            // Define numbering for lists
                            // const numberingStyle = style.numbering;
                            // this.NUMBERING = numberingStyle.map((style, index) => ({
                            //     level: index,
                            //     numbering: style.type,
                            //     indent: style.indent,
                            //     hanging: style.hanging
                            // }));
                            // await context.sync();
                        }
                    } catch (err) {
                        console.warn(`Failed to create style for ${level}:`, err);
                    }
                }
            }

            // set spacing
            // Get all styles in the document and set line spacing
            
            const listParagraphStyle = context.document.getStyles().getByNameOrNullObject("List Paragraph");
            await context.sync();
            if (!listParagraphStyle.isNullObject) {
                listParagraphStyle.paragraphFormat.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                listParagraphStyle.paragraphFormat.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                listParagraphStyle.paragraphFormat.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                await context.sync();
            }

        } catch (error) {
            console.error('Error applying styles:', error);
            // Continue with document creation even if styles fail
        }
    }

    private static async addContent(context: Word.RequestContext, content: any): Promise<void> {
        try {
            // Add title
            if (content.title?.text) {
                const titleParagraph = context.document.body.insertParagraph(content.title.text.toUpperCase(), Word.InsertLocation.start);
                let styleName = content.title.style || 'CustomH1';   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                titleParagraph.style = styleName;  // content.title.style || 'CustomH1';
                await context.sync();
            }

            // Add subtitle
            if (content.subtitle?.text) {
                const subtitleParagraph = context.document.body.insertParagraph(content.subtitle.text.toUpperCase(), Word.InsertLocation.end);
                let styleName = content.subtitle.style || 'CustomH2';   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                subtitleParagraph.style = styleName;  // content.subtitle.style || 'CustomH2';
                await context.sync();
            }

            // Add sections
            if (Array.isArray(content.sections)) {
                let existingList: Word.List;
                // let firstSection = content.sections[0];
                // existingList = await this._isRecital(firstSection) ? null : await this.addSection(context, firstSection, existingList)
                // await context.sync();
                // Add remaining sections
                for (let i = 0; i < content.sections.length; i++) {
                    const section = content.sections[i];
                    if (this._isNotRecital(section)) {
                        if (existingList) {     
                            existingList = await this.addSection(context, section, existingList);
                        } else {
                            existingList =  await this.addSection(context, section, existingList, true);
                        }
                    }
                    else {
                        existingList = await this.addSection(context, section, existingList);
                    }
                    await context.sync();
                }
            }
        } catch (error) {
            console.error('Error adding content:', error);
            // throw error;
        }
    }

    private static async _isNotRecital(section: any): Promise<boolean> {
        return section.title?.text.match(/^\d+/) !== null;
    }

    private static async addSection(context: Word.RequestContext, section: any, existingList: Word.List = null, firstListSection: boolean = false): Promise<any> {
        try {
            // Add section title if it exists
            const isContentEmpty = section.content.length === 0;
            let list: Word.List = null;
            let isNotRecital = false;
            if (section.title?.text) {
                let sectionTitleFullText = section.title.text;
                let titleParagraph;
                // isNotRecital = /^\d+/.test(section.title.text);
                const match = sectionTitleFullText.match(/^(\d+)/);
                const sectionNumber = match ? parseInt(match[1]) : null;
                const sectionTitle = sectionTitleFullText.replace(/^\d+\.?\s*/, '');
                isNotRecital = sectionNumber !== null;

                // Set style for section title
                if (isNotRecital) {
                    // set list
                    if (firstListSection && existingList === null) {
                        titleParagraph = context.document.body.insertParagraph(sectionTitle, Word.InsertLocation.end);
                        list = titleParagraph.startNewList();
                        await context.sync();
                        console.log("New sectionTitle list started");
                    } else {
                        list = existingList;
                        titleParagraph = list.insertParagraph(sectionTitle, Word.InsertLocation.end);
                    }
                    
                    if (sectionNumber == 1) {
                        this.SECTION_NUMBER = sectionNumber;
                    } else if (sectionNumber >= 2) {
                        this.SECTION_NUMBER += 1;
                    }
                    
                    console.log("Setting level numbering: ", sectionNumber);

                    // Define numbering for list
                    // Set numbering for first level (upperLetter)
                    list.setLevelNumbering(0, Word.ListNumbering.arabic, [0, "."]);
                    // Set numbering for second level (lowerLetter)
                    list.setLevelNumbering(1, Word.ListNumbering.arabic, [0, ".", 1, "."]);
                    // Set numbering for third level (decimal)
                    list.setLevelNumbering(2, Word.ListNumbering.arabic, [0, ".", 1, ".", 2, "."]);
                    // Set numbering for fourth level (lowerRoman)
                    list.setLevelNumbering(3, Word.ListNumbering.arabic, [0, ".", 1, ".", 2, ".", 3, "."]);
                    
                    await context.sync();

                    // // Set indents for first level
                    list.setLevelIndents(0, 36, -36);
                    // Set indents for second level
                    list.setLevelIndents(1, 36, -36);
                    // Set indents for third level
                    list.setLevelIndents(2, 72, -36);
                    // Set indents for fourth level
                    list.setLevelIndents(3, 108, -72);
                    await context.sync();

                    // Set font properties
                    titleParagraph.getRange().font.size = this.FONT.size;
                    titleParagraph.getRange().font.name = this.FONT.name;
                    titleParagraph.getRange().font.bold = true;
                    await context.sync();
                    titleParagraph.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                    titleParagraph.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                    titleParagraph.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                    await context.sync();
                    titleParagraph.alignment = Word.Alignment.left;
                    await context.sync();

                    console.log("Setting list item level");

                    // Set list item level
                    titleParagraph.listItem.level = 0; // non-zero based level
                    await context.sync();
                    console.log("List item level set");
    
                } else {
                    titleParagraph = context.document.body.insertParagraph(sectionTitle, Word.InsertLocation.end);
                    const styleName = 'CustomNormal';
                    titleParagraph.style = styleName;
                    await context.sync();
                }

            }

            // Add section content if it exists
            if (Array.isArray(section.content) && !isContentEmpty) {

                for (const contentItem of section.content) {
                    try {
                        list = await this.addContentItem(context, contentItem, isNotRecital, list);
                        await context.sync();
                    } catch (error) {
                        console.error('Error adding content item in section:', error);
                        // Continue with other content items even if one fails
                    }
                }
            }

            return list;
        } catch (error) {
            console.error('Error adding section:', error);
            // throw error;
        }
    }

    private static async addTextWithLinks(context: Word.RequestContext, paragraph: Word.Paragraph, text: string): Promise<void> {
        try {
            // Find all URL patterns and following words: (<url>) next_word
            const parts = text.split(/(\(<https?:\/\/[^>]+>\)\s*\w+)/);

            for (const part of parts) {
                if (part.startsWith('(<http') && part.includes('>)')) {
                    // Extract URL and following word
                    const urlPattern = part.match(/\(<(https?:\/\/[^>]+)>\)\s*(\w+)/);
                    if (urlPattern) {
                        const url = urlPattern[1];
                        const linkText = urlPattern[2];

                        // Add space before link
                        paragraph.insertText(' ', Word.InsertLocation.end);
                        
                        // Add hyperlink
                        await this.addHyperlink(context, paragraph, url, linkText);
                    } else {
                        // Fallback: just add the text as-is
                        paragraph.insertText(part.trim(), Word.InsertLocation.end);
                    }
                } else {
                    // Add regular text
                    if (part.trim()) {
                        paragraph.insertText(part.trim(), Word.InsertLocation.end);
                    }
                }
            }
            await context.sync();
        } catch (error) {
            console.error('Error adding text with links:', error);
        }
    }

    private static async addHyperlink(context: Word.RequestContext, paragraph: Word.Paragraph, url: string, text: string): Promise<void> {
        try {
            // Create hyperlink
            const range = paragraph.insertText(text, Word.InsertLocation.end);
            range.hyperlink = url;
            
            // Apply hyperlink styling
            range.font.color = '0000FF';  // Blue color
            // range.font.underline = true;
            
            await context.sync();
        } catch (error) {
            console.error('Error adding hyperlink:', error);
        }
    }

    private static async addContentItem(context: Word.RequestContext, contentItem: any, isNotRecital: boolean = false, existingList: Word.List = null): Promise<any> {
        try {
            let list: Word.List;
            if (existingList) {
                console.log("USING EXISTING LIST");
                list = existingList;
            }
            if (contentItem.type === 'list') {
                // if (existingList) {
                //     console.log("USING EXISTING LIST");
                //     list = existingList;
                // }

                // Create first paragraph to start the list
                const firstItem = contentItem.items[0];
                let paragraphText = "";  // firstItem.label;
                if (firstItem.paragraphs.length > 0) {
                    for (let j = 0; j < firstItem.paragraphs.length; j++) {
                        paragraphText += firstItem.paragraphs[j];
                        if (j != firstItem.paragraphs.length - 1) {
                            paragraphText += "<br><br>";
                        }
                    }
                }
                
                let firstParagraph: Word.Paragraph;
                await context.sync();
                
                await context.sync();
                
                if (existingList) {
                    firstParagraph = existingList.insertParagraph("", Word.InsertLocation.end);
                } else {
                    firstParagraph = context.document.body.insertParagraph(
                        "", 
                        Word.InsertLocation.end
                    );
                }
                // await this.addTextWithLinks(context, firstParagraph, paragraphText);
                // firstParagraph.style = `CustomList${contentItem.items[0].level}`;
                // await context.sync();

                // add other text
                firstParagraph.insertHtml(paragraphText, Word.InsertLocation.end);
                await context.sync();

                // Start new list with first paragraph
                if (!existingList) {
                    list = firstParagraph.startNewList();
                }

                // Set numbering style
                let styleName = "1 / 1.1 / 1.1.1"; // "customNumberingQanooni"; // `CustomList${contentItem.items[0].level}`;   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (!customStyle.isNullObject){
                    // firstParagraph.style = styleName;
                    // await context.sync();
                    console.log("Style applied successfully");
                }
                else if (customStyle.isNullObject) {
                    // styleName = 'CustomNormal';
                    // firstParagraph.style = styleName;
                    // await context.sync();
                    console.log("Style not found");
                }

                // Load font properties first
                firstParagraph.getRange().font.size = this.FONT.size;
                firstParagraph.getRange().font.name = this.FONT.name;
                firstParagraph.getRange().font.bold = false;
                await context.sync();
                firstParagraph.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                firstParagraph.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                firstParagraph.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                await context.sync();
                firstParagraph.alignment = (firstItem.paragraphs.length > 1) ? Word.Alignment.left : Word.Alignment.justified;
                await context.sync();

                firstParagraph.listItem.level = firstItem.level; // non-zero based level
                await context.sync();

                // Define numbering for list
                if (!existingList) {
                    if (isNotRecital) {
                        // Set numbering for first level (upperLetter)
                        list.setLevelNumbering(0, Word.ListNumbering.arabic, [0, "."]);
                        // Set numbering for second level (lowerLetter)
                        list.setLevelNumbering(1, Word.ListNumbering.arabic, [0, ".", 1, "."]);
                        // Set numbering for third level (decimal)
                        list.setLevelNumbering(2, Word.ListNumbering.arabic, [0, ".", 1, ".", 2, "."]);
                        // Set numbering for fourth level (lowerRoman)
                        list.setLevelNumbering(3, Word.ListNumbering.arabic, [0, ".", 1, ".", 2, ".", 3, "."]);
                    } else {
                        // Set numbering for first level (upperLetter)
                        list.setLevelNumbering(0, Word.ListNumbering.upperLetter, ["(", 0, ")"]);
                        // Set numbering for second level (lowerLetter)
                        list.setLevelNumbering(1, Word.ListNumbering.upperLetter, ["(", 1, ")"]);
                        // Set numbering for third level (decimal)
                        list.setLevelNumbering(2, Word.ListNumbering.lowerLetter, ["(", 2, ")"]);
                        // Set numbering for fourth level (lowerRoman)
                        list.setLevelNumbering(3, Word.ListNumbering.lowerRoman, ["(", 3, ")"]);
                    }
                    await context.sync();

                    // // Set indents for first level
                    list.setLevelIndents(0, 36, -36);
                    // Set indents for second level
                    list.setLevelIndents(1, 36, -36);
                    // Set indents for third level
                    list.setLevelIndents(2, 72, -36);
                    // Set indents for fourth level
                    list.setLevelIndents(3, 108, -72);
                    await context.sync();
                }

                // Handle nested lists if they exist
                if (firstItem.nested_list) {
                    await this.addContentItem(context, firstItem.nested_list, isNotRecital, list);
                }

                // Add remaining items to the list
                for (let i = 1; i < contentItem.items.length; i++) {
                    const item = contentItem.items[i];

                    let paragraphText = ""; //item.label;
                    if (item.paragraphs.length > 0) {
                        for (let j = 0; j < item.paragraphs.length; j++) {
                            paragraphText += item.paragraphs[j];
                            if (j != item.paragraphs.length - 1) {
                                paragraphText += "<br><br>";
                            }
                        }
                    }

                    const listParagraph = list.insertParagraph("", Word.InsertLocation.end);
                    await context.sync();

                    // add other text
                    listParagraph.insertHtml(paragraphText, Word.InsertLocation.end);
                    await context.sync();
                    // await this.addTextWithLinks(context, labelParagraph, paragraphText);
                    
                    // Set numbering style
                    let styleName = "1 / 1.1 / 1.1.1"; // "customNumberingQanooni"; // `CustomList${contentItem.items[0].level}`;   
                    const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                    await context.sync();
                    if (!customStyle.isNullObject){
                        // listParagraph.style = styleName;
                        // await context.sync();
                        console.log("Style applied successfully");
                    }
                    else if (customStyle.isNullObject) {
                        // styleName = 'CustomNormal';
                        // firstParagraph.style = styleName;
                        // await context.sync();
                        console.log("Style not found");
                    }


                    // Load font properties first
                    listParagraph.getRange().font.size = this.FONT.size;
                    listParagraph.getRange().font.name = this.FONT.name;
                    listParagraph.getRange().font.bold = false;
                    await context.sync();
                    listParagraph.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                    listParagraph.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                    listParagraph.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                    await context.sync();
                    listParagraph.alignment = (item.paragraphs.length > 1) ? Word.Alignment.left : Word.Alignment.justified;
                    await context.sync();

                    listParagraph.listItem.level = item.level; // non-zero based level
                    await context.sync();

                    // Handle nested lists if they exist
                    if (item.nested_list) {
                        await this.addContentItem(context, item.nested_list, isNotRecital, list);
                    }
                }
            } else if (contentItem.type === 'text' && contentItem.element){  // && contentItem.element) {
                // list = existingList;

                // Simulating a text paragraph with no numbering
                list.setLevelBullet(4, Word.ListBullet.custom, 0x200B);
                await context.sync();
                console.log("Setting level numbering for text paragraph");
                list.setLevelIndents(4, 0, -(this.LEVEL_INCREMENT));
                await context.sync();
                
                const paragraph = list.insertParagraph("", Word.InsertLocation.end);
                await context.sync();

                paragraph.insertText(contentItem.element.text, Word.InsertLocation.end);
                await context.sync();

                let styleName = 'CustomNormal';  // contentItem.element.style || 'CustomNormal';
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                // paragraph.style = "List Paragraph"; //styleName;
                // await context.sync();


                let range = paragraph.getRange();
                range.font.size = this.FONT.size;
                range.font.name = this.FONT.name;
                range.font.bold = false;
                await context.sync();
                
                paragraph.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                paragraph.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                // paragraph.lineUnitBefore = 1;
                // paragraph.lineUnitAfter = 1;
                await context.sync();

                paragraph.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                paragraph.alignment = Word.Alignment.justified;
                await context.sync();

                paragraph.listItem.level = 4;
                // await this.addTextWithLinks(context, paragraph, contentItem.element.text);
                await context.sync();

            } else if (contentItem.type === 'table') {
                list = existingList;  // TODO: use this
                await this.addTable(context, contentItem, list);
                await context.sync();
            }

            return list;
        } catch (error) {
            console.error('Error adding content item:', error);
            // Continue with other content items even if one fails
        }
    }

    private static async addTable(context: Word.RequestContext, tableItem: any, list: Word.List): Promise<void> {
        const rows = tableItem.element.rows;
        const table = list.insertParagraph("", Word.InsertLocation.end)
        const table_paragraph = table.insertTable(rows.length, rows[0].length, Word.InsertLocation.after);
        
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            for (let colIndex = 0; colIndex < rows[rowIndex].length; colIndex++) {
                const cell = rows[rowIndex][colIndex];
                const cell_text = cell.text.replace(/\n/g, "<br />");
                const cell_paragraph = table_paragraph.getCell(rowIndex, colIndex);
                cell_paragraph.body.insertHtml(cell_text, Word.InsertLocation.end);
                await context.sync();

                let styleName = 'CustomNormal'  // cell.style || 'CustomNormal';
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                // table_paragraph.getCell(rowIndex, colIndex).body.paragraphs[0].style = styleName;

                // Simulating a text paragraph with no numbering
                list.setLevelBullet(4, Word.ListBullet.custom, 0x200B);
                await context.sync();
                console.log("Setting level numbering for text paragraph");
                list.setLevelIndents(4, 0, -(this.LEVEL_INCREMENT));
                await context.sync();

                let range = table_paragraph.getRange();
                range.font.size = this.FONT.size;
                range.font.name = this.FONT.name;
                range.font.bold = false;
                await context.sync();
                
                table.spaceBefore = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                table.spaceAfter = this.FONT.size * this.PARAGRAPH_SPACE_MULTIPLIER;
                // paragraph.lineUnitBefore = 1;
                // paragraph.lineUnitAfter = 1;
                await context.sync();

                table.lineSpacing = this.FONT.size * this.LINE_HEIGHT_MULTIPLIER;
                table.alignment = Word.Alignment.left;
                await context.sync();

                table.listItem.level = 4;
                // await this.addTextWithLinks(context, paragraph, contentItem.element.text);
                await context.sync();
            }
        }
    }
}