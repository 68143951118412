import * as React from "react";

interface ArrowUpIconProps {
  className?: string;
}
export const ArrowUpIcon: React.FC<ArrowUpIconProps> = (props) => {
  return (
    <svg
      className={props.className}
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82052 4.90319C8.70487 5.01674 8.54844 5.08048 8.38538 5.08048C8.22232 5.08048 8.06588 5.01674 7.95024 4.90319L5.08636 2.12304V10.3903C5.08636 10.552 5.02133 10.7071 4.90558 10.8214C4.78983 10.9358 4.63283 11 4.46914 11C4.30544 11 4.14845 10.9358 4.0327 10.8214C3.91695 10.7071 3.85192 10.552 3.85192 10.3903V2.14133L1.05593 4.90319C0.998552 4.96033 0.930287 5.00569 0.855073 5.03664C0.77986 5.06759 0.699186 5.08353 0.617706 5.08353C0.536226 5.08353 0.455552 5.06759 0.380339 5.03664C0.305125 5.00569 0.23686 4.96033 0.179482 4.90319C0.0645248 4.78895 0 4.63443 0 4.47336C0 4.31229 0.0645248 4.15777 0.179482 4.04354L4.11115 0.159864C4.21526 0.0574808 4.35618 0 4.50309 0C4.64999 0 4.79091 0.0574808 4.89502 0.159864L8.82052 4.04354C8.93547 4.15777 9 4.31229 9 4.47336C9 4.63443 8.93547 4.78895 8.82052 4.90319Z"
        fill="currentColor"
      />
    </svg>
  );
};
