import * as React from 'react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { HTMLAttributes } from 'react';

// to be used for parent components
interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}
const PageContainer: React.FC<ContainerProps> = (props: ContainerProps) => {
  const styles = useStyles();
  return (
    <div className={mergeClasses(styles.container, props.className)}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles({
  container: {},
});

export default PageContainer;
