// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kU7TmooLCTNZSaMDoX1S {\n  display: grid;\n  grid-template-columns: 2rem 1fr;\n  padding: 1rem;\n  background-color: #ededed;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/pages/GlobalChangePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".toolbarContainer {\n  display: grid;\n  grid-template-columns: 2rem 1fr;\n  padding: 1rem;\n  background-color: #ededed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": "kU7TmooLCTNZSaMDoX1S"
};
export default ___CSS_LOADER_EXPORT___;
