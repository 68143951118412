import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import styles from './GlobalChange.module.css';
import { GlobeIcon } from '../../../assets/GlobeIcon';
import { ArrowUpIcon } from '../../../assets/ArrowUpIcon';
import { sendGlobalChangeRequest } from '../../../store/officeSlice';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Caption1 } from '@fluentui/react-components'; // Import Spinner
import { useOutletContext } from 'react-router-dom';

interface OutletContextType {
  setShowGlobalChanges: (show: boolean) => void;
}

export const GlobalChange = () => {
  const [globalChangeRequest, setGlobalChangeRequest] = useState('');
  const isProcesssingGlobalChanges = useSelector(
    (state: RootState) => state.office.isProcessingGlobalChanges
  );
  const globalChangesProcessedResults = useSelector(
    (state: RootState) => state.office.globalChangesProcessedResults
  );
  const dispatch = useDispatch<AppDispatch>();
  // @ts-ignore
  const [setShowReviewButton] = useOutletContext();

  const handleSubmit = () => {
    dispatch(sendGlobalChangeRequest(globalChangeRequest));
  };

  useEffect(() => {
    setShowReviewButton(false);
    return () => {
      if (setShowReviewButton) setShowReviewButton(true);
    };
  }, []);

  return (
    <div>
      <div className={styles.globalChangeLabel}>
        <GlobeIcon /> Request Global Changes
      </div>
      <div className={styles.requestContainer}>
        {globalChangesProcessedResults &&
          globalChangesProcessedResults.processedCount !== undefined && (
            <Caption1
              className={styles.resultsInfo}
            >{`${globalChangesProcessedResults.processedCount} Replacements made`}</Caption1>
          )}
        <textarea
          placeholder="Request Global Changes"
          style={{ backgroundColor: 'ghostwhite' }}
          value={globalChangeRequest}
          onChange={e => setGlobalChangeRequest(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          disabled={isProcesssingGlobalChanges} // Disable textarea when loading
        ></textarea>
        {isProcesssingGlobalChanges && (
          <div className={styles.sendIcon}>
            <Spinner size={SpinnerSize.small} />
          </div>
        )}
        {!isProcesssingGlobalChanges && (
          <div className={styles.sendIcon} onClick={handleSubmit}>
            <ArrowUpIcon />
          </div>
        )}
      </div>
    </div>
  );
};
