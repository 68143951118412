import React, { useEffect, useState } from 'react';
import styles from './ReviewTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewCategory, ReviewList } from './components/ReviewList';
import Chat from './components/Chat';
import IntialPage from './components/IntialPage';
import { PosthogEventsNames, ReviewSteps } from './components/const';
import { Clauses } from './components/Clauses';
import { AppDispatch, RootState } from '../../../store/store';
import Header from './components/Header';
import { setReviewStepNumber } from '../../../store/reviewSlice';
import { StatusBar } from '../../shared/statusBar/StatusBar';
import { v4 as uuidv4 } from 'uuid';
import { usePostHog } from 'posthog-js/react';

const ReviewTab = ({ onReturn }: any) => {
  // Scroll to the bottom whenever a new message is added
  const posthog = usePostHog();
  const [reviewCategory, setReviewCategory] =
    useState<ReviewCategory>(undefined);
  const [savedActions, setSavedActions] = useState({});
  const [savedLinkedActions, setSavedLinkedActions] = useState({}); //[currentIndex+category+link index]
  const [typingDelay, settypingDelay] = useState(25);
  const [sessionId, setSessionId] = useState(null);
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  let user_id = useSelector((state: RootState) => state.socket.user_id);
  let setpNo = useSelector((state: RootState) => state.review.reviewStepNumber);
  let reviewProgress = useSelector(
    (state: RootState) => state.review.reviewProgress
  );
  let reviewStatus = useSelector(
    (state: RootState) => state.review.reviewStatus
  );

  const dispatch = useDispatch<AppDispatch>();

  const filteredSuggestions = suggestions.filter(
    x => x.category === reviewCategory
  );
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const setStepNo = (stepNo: number) => {
    dispatch(setReviewStepNumber(stepNo));
  };
  useEffect(() => {
    // Capture the event when the tab is used
    let id = uuidv4();
    if (!sessionId && posthog && user_id) {
      setSessionId(id);
      posthog.capture(PosthogEventsNames.ReviewTabOpened, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: id,
        tab_name: 'review',
        source: 'word',
      });
    }
    if (sessionId) {
      return () => {
        posthog.capture(PosthogEventsNames.ReviewTabClosed, {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          tab_name: 'review',
          source: 'word',
        });
      };
    } else {
      return () => {};
    }
  }, [sessionId, posthog, user_id]);

  return (
    <div
      className={styles.reviewCnt}
      style={{
        marginTop: setpNo == ReviewSteps.chat ? '10px' : undefined,
        height: setpNo !== ReviewSteps.chat ? 'calc(100vh - 88px)' : undefined,
        overflowY: 'auto',
      }}
    >
      <Header
        setpNo={setpNo}
        isAnalyzing={isAnalyzing}
        settypingDelay={settypingDelay}
        setStepNo={setStepNo}
        max={filteredSuggestions.length}
        value={
          Object.keys(savedActions).filter(
            key =>
              key.includes(reviewCategory) && savedActions?.[key] != undefined
          ).length
        }
        sessionId={sessionId}
        reviewCategory={reviewCategory}
      />
      {setpNo == ReviewSteps.initial && <IntialPage setStepNo={setStepNo} />}
      {(setpNo == ReviewSteps.chat || setpNo === undefined) && (
        <Chat
          setStepNo={setStepNo}
          onReturn={onReturn}
          typingDelay={typingDelay}
          settypingDelay={settypingDelay}
          sessionId={sessionId}
          setSessionId={setSessionId}
        />
      )}
      {setpNo == ReviewSteps.review && (
        <div className={styles.reviewCnt} style={{ paddingBottom: '50px' }}>
          <>
            <StatusBar
              estimatedTime="2 minutes"
              taskName="Review contract"
              message={reviewStatus}
              progress={reviewProgress}
            />
            <ReviewList
              setStepNo={setStepNo}
              setReviewCategory={setReviewCategory}
              sessionId={sessionId}
            />
          </>
        </div>
      )}
      {setpNo == ReviewSteps.clauses && (
        <Clauses
          reviewCategory={reviewCategory}
          setStepNo={setStepNo}
          savedActions={savedActions}
          setSavedActions={setSavedActions}
          savedLinkedActions={savedLinkedActions}
          setSavedLinkedActions={setSavedLinkedActions}
          suggestions={suggestions}
          sessionId={sessionId}
        />
      )}
    </div>
  );
};

export default ReviewTab;
