import { BaseRequest, BaseResponse, ResponseEnum } from './base';
import { SectionSummaryResponseChunk } from './review';
import { DocumentAttachment } from './attachment';

export interface DraftDocumentRequest extends BaseRequest {
  query: string;
  template_id?: string;
  thread_id?: string;
  draft_role?: string;
}

export interface DraftDocumentResponse extends BaseResponse {
  response: string;
  document_text: string;
}

export interface DraftDocumentResponseChunk extends BaseResponse {
  response: string;
  document_text: string;
}
// TEMPLATE LIBRARY

export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
  Unknown = 'Unknown',
}

export interface TemplateDocument {
  id?: string;
  name: string;
  type: DocumentType;
  text: string;
  path: string;
}

export interface ListTemplatesRequest extends BaseRequest { }

export interface ListTemplatesResponse extends BaseResponse {
  documents: TemplateDocument[];
}

// CREATE TEMPLATE

export interface CreateTemplateResponse extends BaseResponse {
  document_id: string;
}

export interface DeleteTemplateResponse extends BaseResponse {
  template_id: string;
}

export interface CreateTemplateRequest extends BaseRequest {
  document: TemplateDocument;
}

export interface DeleteTemplateRequest extends BaseRequest {
  template_id: string;
}

export interface UpdateDocumentAttachmentRequest extends BaseRequest {
  document_attachment: DocumentAttachment;
}

// HELPER

export const isDraftDocumentResponse = (
  response: any
): response is DraftDocumentResponse => {
  return response.action === ResponseEnum.DraftDocumentResponse;
};

export const isDraftDocumentResponseChunk = (
  response: any
): response is DraftDocumentResponseChunk => {
  return response.action === ResponseEnum.DraftDocumentResponseChunk;
};

export const isListTemplatesResponse = (
  response: any
): response is ListTemplatesResponse => {
  return response.action === ResponseEnum.ListTemplatesResponse;
};

export const isCreateTemplateResponse = (
  response: any
): response is CreateTemplateResponse => {
  return response.action === ResponseEnum.CreateTemplateResponse;
};

export const isDeleteTemplateResponse = (
  response: any
): response is DeleteTemplateResponse => {
  return response.action === ResponseEnum.DeleteTemplateResponse;
};

export const isSectionSummaryResponseChunk = (
  response: any
): response is SectionSummaryResponseChunk => {
  return response.action === ResponseEnum.SectionSummaryResponseChunk;
};
