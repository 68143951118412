import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Toast,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components';
import styles from './DraftReviewPage.module.css';
import { ReviewProgress } from '../reviewComponents/ReviewProgress';
import {
  ArrowRotateCounterclockwiseRegular,
  RecordStopRegular,
  ThumbLike24Regular,
  ThumbDislike24Regular,
} from '@fluentui/react-icons';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { GLOBAL_TOAST_ID, Layout } from './Layout';
import { useEffect, useState } from 'react';
import {
  clearSuggestions,
  loadWordDocument,
  setIsAnalyzing,
  setPreventAnalyzing,
} from '../../../store/officeSlice';
import { initApp } from '../../../store/rootReducer';
import { LeftArrowIcon } from '../../../assets/LeftArrowIcon';

import { FeedbackGetRequest } from '../../../models/feedback';
import { ActionEnum } from '../../../model/base';
import { sendMessage } from '../../../store/socketSlice'; // Import socket actions

export interface DraftReviewPageProps {}

export const DraftReviewPage: React.FC<DraftReviewPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);

  const [isLikeModalOpen, setIsLikeModalOpen] = React.useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = React.useState(false);
  const [feedbackContent, setFeedbackContent] = React.useState<string>('');

  const socketError = useSelector((state: RootState) => state.socket.socketError);
  const isConnected = useSelector((state: RootState) => state.socket.isConnected);
  const isAnalyzing = useSelector((state: RootState) => state.office.isAnalyzing);

  const [showReviewButton, setShowReviewButton] = useState(true);

  useEffect(() => {
    dispatch(setIsAnalyzing(false));
  }, []);

  useEffect(() => {
    if (socketError) {
      dispatch(setIsAnalyzing(false));

      dispatchToast(
        <Toast>
          <ToastTitle>Error: {socketError}</ToastTitle>
        </Toast>,
        { position: 'top-end', intent: 'error' }
      );
    }
  }, [socketError]);

  const reviewDocument = () => {
    dispatch(setPreventAnalyzing(false));
    dispatch(setIsAnalyzing(true));
    dispatch(clearSuggestions());
    dispatch(loadWordDocument());
  };

  const cancelReview = () => {
    dispatch(initApp());
    dispatch(setIsAnalyzing(false));
  };

  const handleDisplayLikeModal = () => {
    setIsLikeModalOpen(!isLikeModalOpen);
  };

  const handleDisplayDislikeModal = () => {
    setIsDislikeModalOpen(!isDislikeModalOpen);
  };

  const sendFeedbackGet = () => {
    const request: FeedbackGetRequest = {
      action: ActionEnum.FeedbackGetRequest,
      content: feedbackContent,
    };

    dispatch(sendMessage(request)); // Use sendMessage action instead of WebSocket send
  };

  return (
    <Layout
      header={
        <div className={styles.headerContainer}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </div>
          <ReviewProgress />
        </div>
      }
      footer={<></>}
    >
      <div className={styles.menuButtonContainer}>
        <div className={styles.popupButton}>
          <div className={styles.tabContentHeader}></div>
          <ThumbLike24Regular className={styles.likeIcon} onClick={handleDisplayLikeModal} />
          <ThumbDislike24Regular className={styles.dislikeIcon} onClick={handleDisplayDislikeModal} />
        </div>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton>Actions</MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => navigate('globalChanges')}>Global Changes</MenuItem>
              <MenuItem onClick={() => navigate('/emailDocument')}>Send to Outlook</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>

      <div className={styles.buttonContainer}>
        {showReviewButton && isAnalyzing && (
          <Button icon={<RecordStopRegular />} onClick={cancelReview} className={styles.button}>
            <div className={styles.label}>
              <span style={{ color: '#0387F5' }}>Cancel review</span>
            </div>
          </Button>
        )}

        {showReviewButton && !isAnalyzing && (
          <Button
            disabled={isAnalyzing || !isConnected} // Disable if WebSocket is not connected
            icon={<ArrowRotateCounterclockwiseRegular color="#0387F5" />}
            onClick={reviewDocument}
            className={styles.button}
          >
            <div className={styles.label}>
              <span style={{ color: '#0387F5' }}>Review Document</span>
            </div>
          </Button>
        )}
      </div>

      {isLikeModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Please tell us what you like about it</h2>
            <textarea
              className={styles.textArea}
              placeholder="Write here ..."
              onChange={(e) => setFeedbackContent(e.target.value)}
            ></textarea>
            <div className={styles.modalActions}>
              <button className={styles.notNowButton} onClick={handleDisplayLikeModal}>
                Not Now
              </button>
              <button className={styles.sendButton} onClick={sendFeedbackGet}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      {isDislikeModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Please tell us what is wrong that you don't like.</h2>
            <textarea
              className={styles.textArea}
              placeholder="Write here ..."
              onChange={(e) => setFeedbackContent(e.target.value)}
            ></textarea>
            <div className={styles.modalActions}>
              <button className={styles.notNowButton} onClick={handleDisplayDislikeModal}>
                Not Now
              </button>
              <button className={styles.sendButton} onClick={sendFeedbackGet}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}
      <Outlet context={[setShowReviewButton]} />
    </Layout>
  );
};
