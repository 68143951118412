import React, { useEffect, useState } from 'react';
import styles from './GlobalChange.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  sendGlobalChangeRequest,
  setGlobalChatMessages,
} from '../../../store/officeSlice';
import BotChating from '../../shared/botChating/BotChating';
import { GlobalChangeWordResponse } from '../../../models/globalChanges';

const GlobalChange = ({}: any) => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();
  const globalChatMessages: { role: string; message: string }[] = useSelector(
    (state: RootState) => state.office.globalChatMessages //alternative messages messages
  );
  const globalChanges: GlobalChangeWordResponse = useSelector(
    (state: RootState) => state.office.globalChanges
  );
  const isProcessingGlobalChanges: boolean = useSelector(
    (state: RootState) => state.office.isProcessingGlobalChanges //alternative messages messages
  );
  const [isGlobalChangeInitalized, setIsGlobalChangeInitalized] = useState(false);


  const sendBotMessage = (type: string, message: string) => {
    const chat = [
      ...globalChatMessages,
      {
        role: type,
        message: message,

      },

    ];
    dispatch(setGlobalChatMessages(chat));
  };

  const handleSubmit = () => {
    const lastMessage = value;
    sendBotMessage("user", lastMessage)
    dispatch(sendGlobalChangeRequest(lastMessage));
    setIsGlobalChangeInitalized(true);
    setValue('');
  };


  useEffect(() => {
    if(globalChanges?.replacements && isProcessingGlobalChanges == false){
      sendBotMessage("assistant", globalChanges?.response || "Document Modified Successfully!.")
      setIsGlobalChangeInitalized(false);
    }
  }, [globalChanges?.replacements, isProcessingGlobalChanges])


  return (
    <div className={styles.globalCnt}>
      <div className={styles.globalTitle}>Global Changes</div>
      <BotChating
        loading={isProcessingGlobalChanges}
        onMessageSend={() => {
          handleSubmit();
        }}
        chatMessages={globalChatMessages}
        value={value}
        setValue={setValue}
        messageBoxHeight='100px'
        messageBoxWidth='100%'
        isGlobalChangeInitalized={isGlobalChangeInitalized}
      />
    </div>
  );
};

export default GlobalChange;
