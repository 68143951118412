import * as React from 'react';
import styles from './ReviewList.module.css';
import classNames from 'classnames/bind';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@fluentui/react-components';

export enum ReviewCategory {
  Unacceptable = 'Unacceptable',
  NonStandard = 'Non-Standard',
  Acceptable = 'Acceptable',
  Missing = 'Missing',
}
interface ReviewListItemProps {
  count: number;
  label: string;
  reviewCategory: ReviewCategory;
}
const ReviewListItem = (props: ReviewListItemProps) => {
  const navigate = useNavigate();
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const cx = classNames.bind(styles);
  const className = cx({
    reviewItemContainer: true,
    unacceptable: props.reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: props.reviewCategory === ReviewCategory.NonStandard,
    acceptable: props.reviewCategory === ReviewCategory.Acceptable,
    missing: props.reviewCategory === ReviewCategory.Missing,
  });

  return (
    <div
      className={className}
      onClick={() => {
        if (props.count === 0) return;
        navigate('clauses', {
          state: { reviewCategory: props.reviewCategory },
        });
      }}
    >
      <div className={styles.counter}>{props.count}</div>
      <p>{props.label}</p>
      <div className={styles.loading}>{isAnalyzing && <Spinner />}</div>
    </div>
  );
};

export interface ReviewListProps {}
export const ReviewList: React.FC<ReviewListProps> = () => {
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  suggestions = suggestions.filter(x => x.user_suggestion_action !== 'deleted');
  return (
    <div className={styles.container}>
      <ReviewListItem
        count={
          suggestions.filter(x => x.review_category === 'Unacceptable').length
        }
        label="Unacceptable"
        reviewCategory={ReviewCategory.Unacceptable}
      />
      <ReviewListItem
        count={
          suggestions.filter(x => x.review_category === 'Non-Standard').length
        }
        label="Non-Standard"
        reviewCategory={ReviewCategory.NonStandard}
      />
      <ReviewListItem
        count={
          suggestions.filter(x => x.review_category === 'Acceptable').length
        }
        label="Acceptable"
        reviewCategory={ReviewCategory.Acceptable}
      />
      <ReviewListItem
        count={suggestions.filter(x => x.review_category === 'Missing').length}
        label="Missing"
        reviewCategory={ReviewCategory.Missing}
      />
    </div>
  );
};
