import React, { useEffect, useRef, useState } from 'react';
import { MessageRole, ReviewChatMessage } from '../../../../models/chat';
import {
  sendChatMessage,
  setchatingLoading,
  setChatMessages,
  setReviewProgress,
  setReviewStatus,
} from '../../../../store/reviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  clearSuggestions,
  setIsAnalyzing,
  setPreventAnalyzing,
} from '../../../../store/officeSlice';
import BotChating from '../../../shared/botChating/BotChating';
import { sendMessage } from '../../../../store/socketSlice';
import { PosthogEventsNames, REVIEW_STATUS, ReviewSteps } from './const';
import styles from '../ReviewTab.module.css';
import { v4 as uuidv4 } from 'uuid';
import { usePostHog } from 'posthog-js/react';

const Chat = ({
  onReturn,
  setStepNo,
  typingDelay,
  settypingDelay,
  sessionId,
  setSessionId,
}: any) => {
  const posthog = usePostHog();
  const [value, setValue] = React.useState('');
  const [isReviewPromptAsked, setIsReviewPromptAsked] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [stepSessionId, setStepSessionId] = useState(null);
  const chatMessages: ReviewChatMessage[] = useSelector(
    (state: RootState) => state.review.chatMessages
  );
  let user_id = useSelector((state: RootState) => state.socket.user_id);

  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const documentId = useSelector((state: RootState) => state.office.documentId);
  let chatingLoading = useSelector(
    (state: RootState) => state.review.chatingLoading
  );
  const party = useSelector((state: RootState) => state.review.party);
  const additionalInstructions = useSelector(
    (state: RootState) => state.review.additional_instructions
  );
  const affirmativeResponses = [
    'yes',
    'sure',
    'i guess',
    'shoot it',
    'go ahead',
    'start',
    'begin',
    'lets roll',
    'yes please',
    'yea',
    'yeah',
    'proceed',
    'please',
    'please proceed',
    'please start',
    'definitely',
    'absolutely',
    'of course',
    'ok',
    'okay',
    'ok sure',
    'ok sure',
    'ok go ahead',
    'ok go ahead',
    'ok proceed',
    'ok proceed',
    'ok start',
    'ok start',
    'ok begin',
    'ok begin',
    'ok lets roll',
    'ok lets roll',
    'ok yes please',
    'ok yes please',
    'ok yea',
    'ok yea',
    'ok yeah',
    'ok yeah',
    'ok definitely',
    'ok definitely',
    'ok absolutely',
    'ok absolutely',
    'ok of course',
    'ok of course',
  ];
  const negativeResponses = ['no', 'nah', 'nope', 'not now', 'not yet'];
  const onMessageSend = () => {
    if (typingDelay === 0) {
      settypingDelay(25);
    }
    if (isConnected && value !== '') {
      if (
        affirmativeResponses.includes(value.trim().toLowerCase()) &&
        isReviewPromptAsked
      ) {
        reviewDocument();
      } else if (
        negativeResponses.includes(value.trim().toLowerCase()) &&
        isReviewPromptAsked
      ) {
        dispatch(setChatMessages([]));
        onReturn();
      } else {
        dispatch(
          sendChatMessage({
            id: uuidv4(),
            message: value,
            role: MessageRole.User,
            isChunk: false,
          })
        );
        posthog.capture(PosthogEventsNames.ReviewTabChatUserAnswerSent, {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          stepSessionId: stepSessionId,
          tab_name: 'review',
          source: 'word',
          review_step: 'chat',
          answer: value,
          documentId: documentId,
        });
      }
      setValue('');
    }
  };
  useEffect(() => {
    if (isConnected && chatMessages.length == 0 && stepSessionId) {
      dispatch(
        sendChatMessage({
          id: uuidv4(),
          message: 'Hi',
          role: MessageRole.User,
          isChunk: false,
          sessionId: sessionId,
          stepSessionId: stepSessionId,
          time: new Date().toISOString(),
          user_id: user_id,
        })
      );
    }
    // setdelay(40);
    // Check if the last message is the review prompt
    if (chatMessages.length > 0) {
      const lastMessage = chatMessages[chatMessages.length - 1].message;
      if (lastMessage.includes('Shall I start the review?')) {
        setIsReviewPromptAsked(true);
      }
    }
  }, [isConnected, chatMessages, stepSessionId]);

  const reviewDocument = () => {
    let id = sessionId;
    if (!sessionId) {
      id = uuidv4();
      setSessionId(id);
    }
    dispatch(setPreventAnalyzing(false));
    dispatch(setIsAnalyzing(true));
    dispatch(clearSuggestions());
    dispatch(setReviewProgress(0));
    dispatch(setReviewStatus(REVIEW_STATUS.review));
    dispatch(
      sendMessage({
        action: 'DocumentReviewRequestV2',
        user_document_id: documentId,
        party: party,
        additional_instructions: additionalInstructions,
        session_id: id,
        sending_time: new Date().toISOString(),
      })
    );
    posthog.capture(PosthogEventsNames.ReviewTabReviewDocumentStarted, {
      distinct_id: user_id, // Use a unique identifier for the user/session
      time: new Date().toISOString(),
      session_id: id,
      tab_name: 'review',
      source: 'word',
      documentId: documentId,
      party: party,
      additional_instructions: additionalInstructions,
    });
    setStepNo(ReviewSteps.review);
  };
  useEffect(() => {
    // Capture the event when the tab is used

    let id = uuidv4();
    if (!stepSessionId && posthog && user_id && sessionId) {
      console.log('tab chat opened', stepSessionId);

      setStepSessionId(id);
      posthog.capture(PosthogEventsNames.ReviewTabChatOpened, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: sessionId,
        stepSessionId: id,
        tab_name: 'review',
        source: 'word',
        review_step: 'chat',
        documentId: documentId,
      });
      return () => {
        console.log('tab chat closed', stepSessionId);
        posthog.capture(PosthogEventsNames.ReviewTabChatClosed, {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          tab_name: 'review',
          source: 'word',
          review_step: 'chat',
          stepSessionId: id,
          documentId: documentId,
        });
      };
    } else {
      return () => {};
    }
  }, [sessionId]);

  useEffect(() => {
    chatMessages.map(c => {
      if (c.message === '...' || c.isChunk) {
        dispatch(setchatingLoading(true));
      } else {
        dispatch(setchatingLoading(false));
      }
    });
  }, [chatMessages]);

  return (
    <div>
      <div className={styles.reviewTitle}>Review</div>
      <BotChating
        typingDelay={typingDelay}
        height="60vh"
        loading={chatingLoading}
        onYes={() => {
          reviewDocument();

          //dispatch(setChatMessages([]));
        }}
        onNo={() => {
          dispatch(setChatMessages([]));
          onReturn();
        }}
        onMessageSend={onMessageSend}
        chatMessages={chatMessages.slice(1).map(c => {
          return { ...c, is_yes_no: c.is_finished };
        })}
        value={value}
        setValue={setValue}
        messageBoxHeight="100px"
        messageBoxWidth="100%"
      />
    </div>
  );
};

export default Chat;
