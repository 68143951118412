import { getMatchingParagraph, getSantitizedParagraphs } from './textMining';

export async function insertTextAfterClause(old_text: string, newText: string) {
  await Word.run(async context => {
    let prevClauseText = old_text;
    if (prevClauseText.includes(' (a) ')) {
      prevClauseText = prevClauseText.split(' (a) ')[0];
    }
    if (prevClauseText.includes(' (i) ')) {
      prevClauseText = prevClauseText.split(' (i) ')[0];
    }
    // Use getMatchingParagraph to find the previous clause's paragraph (e.g., 5.3)
    let sanitizedParagraphs_updated = await getSantitizedParagraphs(context);
    let matchingParagraph = getMatchingParagraph(
      prevClauseText.trim(),
      sanitizedParagraphs_updated
    );
    let parentParagraph: Word.Paragraph;
    let parent_has_list;
    if (matchingParagraph) {
      const wordParagraphs = context.document.body.paragraphs;
      wordParagraphs.load('text');
      await context.sync();
      parentParagraph = wordParagraphs.items[matchingParagraph.index];
      try {
        parentParagraph.load('listItem');
        await context.sync();
        parent_has_list = true;
      } catch {
        parent_has_list = false;
      }
    }
    if (!matchingParagraph) {
      console.log('no matching paragraph founded');
      var body = context.document.body;
      // Search for the starting sentence of the clause
      var searchResults = body.search(prevClauseText.slice(0, 150), {
        matchCase: false,
        matchWholeWord: false,
      });
      context.load(searchResults, 'items');
      await context.sync();
      if (searchResults.items.length > 0) {
        parentParagraph = searchResults.items[0].paragraphs.getLast();
        try {
          parentParagraph.load('listItem');
          await context.sync();
          parent_has_list = true;
        } catch {
          parent_has_list = false;
        }
      } else {
        console.log('no results founded');
        return;
      }
    }

    let next;
    let new_paragraph = null;
    let LoopFlag = true;

    try {
      next = parentParagraph.getNext();
      new_paragraph = parentParagraph.getNext();
      if (parent_has_list) {
        new_paragraph.load('listItem');
        new_paragraph.load('leftIndent');
        new_paragraph.load('list');
        await context.sync();
        parentParagraph.load('leftIndent');
        parentParagraph.load('list');
        await context.sync();
        if (
          new_paragraph.listItem.level < parentParagraph.listItem.level ||
          new_paragraph.leftIndent < parentParagraph.leftIndent
        ) {
          new_paragraph = null;
          LoopFlag = false;
        }
      }
    } catch {
      console.log('no next paragraph');
      new_paragraph = null;
      LoopFlag = false;
    }
    while (LoopFlag && parent_has_list) {
      new_paragraph.load('leftIndent');
      parentParagraph.load('leftIndent');
      await context.sync();
      if (
        new_paragraph.listItem.level <= parentParagraph.listItem.level &&
        new_paragraph.leftIndent <= parentParagraph.leftIndent
      ) {
        break;
      } else {
        try {
          new_paragraph = next.getNext();
          await context.sync();
          new_paragraph.load('listItem');
          await context.sync();
          next = new_paragraph;
        } catch {
          console.log('no next paragraph');
          break;
        }

        continue;
      }
    }
    let lines = formatStringWithLevels(newText);
    let final_paragraph: Word.Paragraph;
    let base_level;
    if (new_paragraph) {
      final_paragraph = (new_paragraph as Word.Paragraph).insertParagraph(
        // newText.trim().replace(/\n/g, '\r'),
        lines[0].text,
        Word.InsertLocation.before
      );
      final_paragraph.load('style');
      final_paragraph.load('styleBuiltIn');
      parentParagraph.load('style');
      parentParagraph.load('styleBuiltIn');
      final_paragraph.load('leftIndent');
      parentParagraph.load('leftIndent');
      await context.sync();
      final_paragraph.style = parentParagraph.style;
      await context.sync();
      final_paragraph.leftIndent = parentParagraph.leftIndent;
      await context.sync();
      try {
        final_paragraph.load('listItem');
        await context.sync();
        final_paragraph.listItem.set({
          level: parentParagraph.listItem.level,
        });
        await context.sync();
        base_level = final_paragraph.listItem.level;
      } catch (e) {
        console.log(e);
        try {
          parentParagraph.load('list');
          parentParagraph.load('listItem');
          await context.sync();
          final_paragraph.attachToList(
            parentParagraph.list.id,
            parentParagraph.listItem.level
          );
          await context.sync();
          base_level = final_paragraph.listItem.level;
        } catch (e) {
          console.log(e);
          base_level = null;
        }
      }
      final_paragraph.select();
      await context.sync();
    } else {
      final_paragraph = parentParagraph.insertParagraph(
        lines[0].text,
        Word.InsertLocation.after
      );
      await context.sync();
      final_paragraph.load('style');
      final_paragraph.load('listItem');
      parentParagraph.load('style');
      final_paragraph.load('leftIndent');
      parentParagraph.load('leftIndent');
      await context.sync();
      final_paragraph.leftIndent = parentParagraph.leftIndent;
      await context.sync();
      try {
        base_level = final_paragraph.listItem.level;
      } catch (e) {
        console.log(e);
        base_level = null;
      }
      try {
        final_paragraph.select();
        await context.sync();
      } catch (e) {
        console.log(e);
      }
    }

    //let next_line_p = final_paragraph;
    if (lines.length > 1) {
      let next_p = final_paragraph;
      let new_p: Word.Paragraph;
      await context.sync();
      for (let i = 1; i < lines.length; i++) {
        new_p = next_p.insertParagraph(lines[i].text, 'After');
        if (base_level) {
          new_p.listItem.set({
            level: base_level + 1,
          });
          await context.sync();
        } else {
          final_paragraph.load('leftIndent');
          new_p.load('leftIndent');
          await context.sync();
          new_p.styleBuiltIn = 'Normal';
          new_p.leftIndent = final_paragraph.leftIndent;
          new_p.spaceBefore = 10;
          final_paragraph.styleBuiltIn = 'Strong';
          await context.sync();
        }

        next_p = new_p;
        await context.sync();
      }
    }
  });
}

export function removeClauseByText(text: string) {
  Word.run(async context => {
    const body = context.document.body;
    let lines = formatStringWithLevels(text);
    for (let i = 0; i < lines.length; i++) {
      const normalizedText = lines[i].text.trim();
      var searchResults = body.search(normalizedText.slice(0, 150), {
        matchCase: false,
        matchWholeWord: false,
      });
      context.load(searchResults, 'items');
      await context.sync();
      if (searchResults.items.length > 0) {
        const paragraph = searchResults.items[0].paragraphs.getLast();
        paragraph.delete();
        await context.sync();
      } else {
        let sanitizedParagraphs_updated = await getSantitizedParagraphs(
          context
        );
        let matchingParagraph = getMatchingParagraph(
          text.trim(),
          sanitizedParagraphs_updated
        );
        if (matchingParagraph) {
          const wordParagraphs = context.document.body.paragraphs;
          wordParagraphs.load('text');
          await context.sync();
          const paragraph = wordParagraphs.items[matchingParagraph.index];
          paragraph.delete();
          await context.sync();
        } else {
          console.log('no matching paragraphs to delete');
        }
      }
    }
  });
}
// if the recommended insertion is 3.1(j) the text before should be for 3.1(i)
// if it is 3.2 so text before should be for 3.1
// if it is 4. the text before should be text for 3.
// if there is recommendation for 7.3 two times one become 7.3 and the other one become 7.4
function formatStringWithLevels(input) {
  const parts = input.split(/(\n+)/);
  const lines = [];

  // Process the first line (no preceding newlines)
  if (parts[0] !== '') {
    lines.push({ text: parts[0], level: 0 });
  }

  // Process subsequent lines
  for (let i = 1; i < parts.length; i += 2) {
    const newlines = parts[i];
    const text = parts[i + 1] || ''; // Handle trailing newlines

    // Skip empty text segments (caused by trailing newlines)
    if (text === '') continue;

    // Determine the level based on preceding newlines count
    const level = newlines.length;

    lines.push({ text: text.replace(/^\(.*?\)\s*/, ''), level: level });
  }

  return lines;
}
