import * as React from 'react';
import styles from './Layout.module.css';
import { Link, Toaster } from '@fluentui/react-components';

import { ChatContainer } from '../chat/ChatContainer';

export const GLOBAL_TOAST_ID = 'global';
interface LayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  styles?: React.CSSProperties;
}
export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{props.header}</div>
      <div className={styles.main}>{props.children}</div>
      <div className={styles.footer}>
        {props.footer}
        <ChatContainer />
        <p>
          Your data is private and secure <Link href="#">Learn more</Link>
        </p>
      </div>
      <Toaster toasterId={GLOBAL_TOAST_ID} />
    </div>
  );
};
