export enum StatusEnum {
  Success = 'success',
  Error = 'error',
}

export enum ResponseEnum {
  OpenWordResponse = 'OpenWordResponse',
  DocumentSessionStarted = 'DocumentSessionStarted',
  DocumentReviewResponse = 'DocumentReviewResponse',
  SuggestionResponse = 'Suggestion',
  DraftDocumentResponse = 'DraftDocumentResponse',
  DraftDocumentResponseChunk = 'DraftDocumentResponseChunk',
  ListTemplatesResponse = 'ListTemplatesResponse',
  CreateTemplateResponse = 'CreateTemplateResponse',
  SectionSummaryResponseChunk = 'SectionSummaryResponseChunk',
  ChatWordResponse = 'ChatWordResponse',
  ChatWordResponseChunk = 'ChatWordResponseChunk',
  UpdateDocumentAttachmentResponse = 'UpdateDocumentAttachmentResponse',
  AnnotationTaskResponse = "AnnotationTask",
  DeleteTemplateResponse = "DeleteTemplateResponse"
}

export enum RequestEnum {
  OpenWordRequest = 'OpenWordRequest',
  DocumentReviewRequest = 'DocumentReviewRequest',
  DraftDocumentRequest = 'DraftDocumentRequest',
  ListTemplatesRequest = 'ListTemplatesRequest',
  CreateTemplateRequest = 'CreateTemplateRequest',
  UpdateDocumentAttachmentRequest = 'UpdateDocumentAttachmentRequest',
  ChatWordRequest = 'ChatWordRequest',
  SendToAnnotationQueueRequest = 'SendToAnnotationQueueRequest',
  DeleteTemplateRequest = 'DeleteTemplateRequest',
}

export interface BaseResponse {
  action: ResponseEnum;
  status: StatusEnum;
  errorMessage?: string | null;
}

export interface BaseRequest {
  action: RequestEnum;
}
