// Spinner.tsx
import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <div style={{
        border: '8px solid #f3f3f3',      // Reduced from 16px to 8px
        borderTop: '8px solid #3498db',   // Reduced from 16px to 8px
        borderRadius: '50%',
        width: '60px',                   // Reduced from 120px to 60px
        height: '60px',                  // Reduced from 120px to 60px
        animation: 'spin 2s linear infinite'
      }}></div>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Spinner;

