export async function replaceDocumentContent(
  newContent: string
): Promise<void> {
  try {
    await Word.run(async function (context) {
      console.log(`Replacing document content with: ${newContent}`);
      const body = context.document.body;
      body.clear();

      if (isValidHtml(newContent)) {
        console.log('Content is valid HTML');
        body.insertHtml(newContent, 'End');
      } else {
        console.log('Inserting text instead of HTML');
        body.insertText(newContent, 'End');
      }

      await context.sync().then(function () {
        console.log('Content replaced successfully.');
      });

      console.log('New content added to the document body.');
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  } catch (error) {
    console.error(`Failed to insert text: ${error}`);
  }
}

export async function getDocumentContentAsHtml(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;
    const bodyHtml = body.getHtml();

    await context.sync();

    console.log('Retrieved document content as HTML:', bodyHtml.value);
    return bodyHtml.value;
  }).catch(function (error) {
    console.error('Error retrieving document as HTML:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as HTML: ${error}`);
  });
}

export async function getDocumentContentAsText(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;

    // Load the 'text' property of the 'body' object
    body.load('text');

    await context.sync();

    const text = body.text;

    console.log('Retrieved document content as Text:', text);
    return text;
  }).catch(function (error) {
    console.error('Error retrieving document as Text:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as Text: ${error}`);
  });
}

function isValidHtml(htmlString: string): boolean {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const parserErrors = doc.querySelectorAll('parsererror');

  return parserErrors.length === 0;
}
