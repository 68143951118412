// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cxP4P086AowJyH5PG2qr {\n  display: grid;\n  grid-template-columns: 2rem 1fr;\n  padding: 1rem;\n  background-color: #fff;\n}\n\n.n3Qp2ae2Wva5sgUOxCCQ {\n  display: flex;\n  justify-content: flex-end;\n  column-gap: 0.5rem;\n\n  > button {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0.02em;\n    text-align: left;\n    /*flex-basis: 100%;*/\n  }\n}\n.ct5TnLP2qkyVe7FTPP98 {\n  font-size: 20px;\n  font-weight: 400;\n  margin-bottom: 20px;\n  /* margin-top: 20px; */\n  color: #626262;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/pages/draft/DraftPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;;EAElB;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,oBAAoB;EACtB;AACF;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".toolbarContainer {\n  display: grid;\n  grid-template-columns: 2rem 1fr;\n  padding: 1rem;\n  background-color: #fff;\n}\n\n.analyzeButtonContainer {\n  display: flex;\n  justify-content: flex-end;\n  column-gap: 0.5rem;\n\n  > button {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0.02em;\n    text-align: left;\n    /*flex-basis: 100%;*/\n  }\n}\n.heading {\n  font-size: 20px;\n  font-weight: 400;\n  margin-bottom: 20px;\n  /* margin-top: 20px; */\n  color: #626262;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": "cxP4P086AowJyH5PG2qr",
	"analyzeButtonContainer": "n3Qp2ae2Wva5sgUOxCCQ",
	"heading": "ct5TnLP2qkyVe7FTPP98"
};
export default ___CSS_LOADER_EXPORT___;
