import { BaseRequest, BaseResponse, ResponseEnum } from './base';
import { SectionSummaryResponseChunk } from './review';
import { DocumentAttachment } from './attachment';
import { ChatMessage } from './chat';

export interface DraftDocumentRequest extends BaseRequest {
  template_id?: string;
  thread_id?: string;
  chat_history: ChatMessage[];
  retained_info?: JSON;
  blank_file_path: string;
}

export interface DraftDocumentResponse extends BaseResponse {
  response: string;
  document_text: string;
  chunk_index: number;
  total_chunks: number;
  is_complete: boolean;
  docx_base64: any;  //string;
  task_id: string | null;
}

export interface DraftDocumentResponseChunk extends BaseResponse {
  response: string;
  document_text: string;
  chunk_index: number;
  total_chunks: number;
  is_complete: boolean;
  task_id: string;
  estimated_time?: number;
}

export interface StopDraftGenerationResponse extends BaseResponse {
  response: string;
  task_id: string;
}

export interface StopDraftGenerationRequest extends BaseRequest {
  task_id: string;
}
// TEMPLATE LIBRARY

export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
  Unknown = 'Unknown',
}

export interface TemplateDocument {
  id?: string;
  name: string;
  type: DocumentType;
  text: string;
  path: string;
}

export interface ListTemplatesRequest extends BaseRequest {}
// CONTRACTS
export type Contract = {
  id: number;
  contract_type: string;
  representing_parties: string;
};

export interface ListContractsResponse extends BaseResponse {
  contracts: Contract[];
}

export interface ListContractsRequest extends BaseRequest {}
export interface DraftChatBotRequest extends BaseRequest {
  question_id?: number;
  current_sequence?: number;
  earlier_user_response?: string;
  retained_info?: JSON;
  chat_history: ChatMessage[];
  draft_mode: string;
  clause: string;
}

export interface DraftChatBotVanillaResponse extends BaseResponse {
  question?: string;
  allow_generate_draft: boolean;
  retained_info?: JSON;
  type?: string;
  response?: string;
  original_clause?: string;
  command_type?: string;
}

export interface DraftChatBotResponse {
  question?: string;
  allow_generate_draft: boolean;
  retained_info?: JSON;
  type?: string;
  response?: string;
  original_clause?: string;
  command_type: string;
}

// TEMPLATE LIBRARY

export interface ListTemplatesResponse extends BaseResponse {
  documents: TemplateDocument[];
}

// CREATE TEMPLATE

export interface CreateTemplateResponse extends BaseResponse {
  document_id: string;
}

export interface DeleteTemplateResponse extends BaseResponse {
  template_id: string;
}

export interface CreateTemplateRequest extends BaseRequest {
  document: TemplateDocument;
}

export interface DeleteTemplateRequest extends BaseRequest {
  template_id: string;
}

export interface UpdateDocumentAttachmentRequest extends BaseRequest {
  document_attachment: DocumentAttachment;
}

// HELPER

export const isDraftDocumentResponse = (
  response: any
): response is DraftDocumentResponse => {
  return response.action === ResponseEnum.DraftDocumentResponse;
};

export const isDraftDocumentResponseChunk = (
  response: any
): response is DraftDocumentResponseChunk => {
  return response.action === ResponseEnum.DraftDocumentResponseChunk;
};

export const isStopDraftGenerationResponse = (
  response: any
): response is StopDraftGenerationResponse => {
  return response.action === ResponseEnum.StopDraftGenerationResponse;
};


export const isListTemplatesResponse = (
  response: any
): response is ListTemplatesResponse => {
  return response.action === ResponseEnum.ListTemplatesResponse;
};

export const isCreateTemplateResponse = (
  response: any
): response is CreateTemplateResponse => {
  return response.action === ResponseEnum.CreateTemplateResponse;
};

export const isDeleteTemplateResponse = (
  response: any
): response is DeleteTemplateResponse => {
  return response.action === ResponseEnum.DeleteTemplateResponse;
};

export const isListContractsResponse = (
  response: any
): response is ListContractsResponse => {
  return response.action === ResponseEnum.ListContractsResponse;
};

export const isDraftChatBotResponse = (
  response: any
): response is DraftChatBotVanillaResponse => {
  return response.action === ResponseEnum.DraftChatBotResponse;
};

export const isSectionSummaryResponseChunk = (
  response: any
): response is SectionSummaryResponseChunk => {
  return response.action === ResponseEnum.SectionSummaryResponseChunk;
};