import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  makeStyles,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  shorthands,
  Spinner,
  Toast,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { v4 as uuidv4 } from 'uuid';

import FileUploader from './FileUploader';

import { AppDispatch, RootState } from '../../../store/store';
import {
  sendCreateTemplateRequest,
  sendDraftRequest,
  SendDraftRequestPayload,
  setUploadedDocument,
  setIsLoadingDraft,
  uploadDocument,
  sendDeleteTemplateRequest,
  setIsGeneratingDraft,
  setDraftResponseChunk,
} from '../../../store/draftSlice';
import { sendAttachmentDocumentRequest } from '../../../store/officeSlice';
import { setChatMessages } from '../../../store/draftSlice';

import {
  DraftDocumentResponseChunk,
  TemplateDocument,
} from '../../../models/draft';
import { getFileIcon, getFileType } from '../../../helper/file';
import { MessageRole } from '../../../models/chat';

import styles from './DraftPage.module.css';
import { ChatMessage } from '../../../models/chat';
import DraftChat, { initalDraftChunkResponse } from './Chat';
import { Layout, GLOBAL_TOAST_ID } from '../../layout/Layout';
import {
  ChangeTrackingMode,
  ChangeViewMode,
  clearDocumentContent,
  getDocumentContentAsText,
  setTrackingStatus,
  setViewMode,
} from '../../../helper/office';
import { getDocumentPath } from '../../../helper/office';

/* global Word, Office */

export const DraftPage: React.FC = () => {
  const customStyles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Set document to edit mode when component mounts
    setTrackingStatus(ChangeTrackingMode.off);
  }, []); // Empty dependency array means this runs once when component mounts

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const hasGeneratedDraft: boolean = useSelector(
    (state: RootState) => state.draft.hasGeneratedDraft
  );
  const isLoadingDraft: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingDraft
  );
  const isLoadingListMandatoryQuestions: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingDraftChatResponse
  );
  const draftError: Error = useSelector(
    (state: RootState) => state.draft.draftError
  );

  const templateDocuments: TemplateDocument[] = useSelector(
    (state: RootState) => state.draft.templateDocuments
  );
  const uploadError: Error = useSelector(
    (state: RootState) => state.draft.uploadError
  );
  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );
  const mandatoryQuestions = useSelector(
    (state: RootState) => state.draft.draftChatBotResponse
  );

  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateDocument | null>(null);

  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);

  // file upload
  const isLoadingUploadedDocument: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingUploadedDocument
  );
  const isLoadingListTemplate: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingListTemplate
  );
  const isLoadingDeleteTemplate: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingDeleteTemplate
  );

  const isEssentialInformationComplete: boolean = useSelector(
    (state: RootState) => state.draft.isEssentialInformationComplete
  );
  const isGeneratingDraft: boolean = useSelector(
    (state: RootState) => state.draft.isGeneratingDraft
  );
  const [isDraggingDoc, setIsDraggingDoc] = React.useState(false);
  const uploadedDocument = useSelector(
    (state: RootState) => state.draft.uploadedDocument
  );

  // useEffect(() => {
  //   const updateOnResponse = () => {
  //     if(isGeneratingDraft == true)
  //     {
  //       const messageForDrafting = "Please, wait while your draft is being generated...";
  //       const botMessage: ChatMessage = {
  //         message: messageForDrafting,
  //         role: MessageRole.Assistant,
  //         id: uuidv4(),
  //         isChunk: false,
  //         attached: '',
  //       };
  //       setTimeout(() => {
  //         dispatch(setChatMessages([...chatMessages, botMessage]));
  //       }, 6000);
  //     }
  //     else if(isGeneratingDraft == false)
  //     {
  //       dispatchToast(
  //         <Toast>
  //           <ToastTitle>Draft Generated Successfully!</ToastTitle>
  //         </Toast>,
  //         { position: 'bottom-end', intent: 'success' }
  //       );
  //       const messageForDrafting = "Your draft has been generated successfully!";
  //       const botMessage: ChatMessage = {
  //         message: messageForDrafting,
  //         role: MessageRole.Assistant,
  //         id: uuidv4(),
  //         isChunk: false,
  //         attached: '',
  //       };
  //       dispatch(setChatMessages([...chatMessages, botMessage]));
  //     }
  //   }
  //   updateOnResponse()
  // }, [isGeneratingDraft])

  const onTemplateSelect = (
    _event: SelectionEvents,
    data: OptionOnSelectData
  ): void => {
    const templateId = data.optionValue;
    const templateDocument = templateDocuments.find(
      template => template.id === templateId
    );
    console.log('Selected template:', templateDocument);
    setSelectedTemplate(templateDocument || null);
  };

  const sendDocumentAsAttachment = () => {
    console.log('Sending document as attachment');
    dispatch(sendAttachmentDocumentRequest());
  };

  const startDraftGeneration = async() => {
    if (!prompt) {
      dispatchToast(
        <Toast>
          <ToastTitle>Please enter a prompt</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'error' }
      );
      return;
    }
    // const retainedInfo = mandatoryQuestions.retained_info;
    // const messageForDrafting = `I will now proceed to generate your draft of **${retainedInfo['contract_type']}** for parties **${retainedInfo['representing_party']}** and **${retainedInfo['other_party']}**, with respective roles **${retainedInfo['representing_party_role']}** and **${retainedInfo['other_party_role']}** in accordance with the legal jurisdiction of the **${retainedInfo['governing_law']}**.`;
    // const botMessage: ChatMessage = {
    //   message: messageForDrafting,
    //   role: MessageRole.Assistant,
    //   id: uuidv4(),
    //   isChunk: false,
    //   attached: '',
    // };
    // dispatch(setChatMessages([...chatMessages, botMessage]));
    await clearDocumentContent()
    dispatch(setIsGeneratingDraft(true));
    dispatch(setDraftResponseChunk(initalDraftChunkResponse))
    const blankFilePath = await getDocumentPath() || '';
    console.log('Blank file path:', blankFilePath);
    const payload: SendDraftRequestPayload = {
      template: selectedTemplate,
      chat_history: chatMessages,
      blank_file_path: blankFilePath,
      retained_info: mandatoryQuestions.retained_info,
    };
    dispatch(sendDraftRequest(payload));
  };

  // FILE UPLOAD LOGIC

  const [isToggleOpenForUploadDocument, setIsToggleOpenForUploadDocument] =
    useState(false);
  const [isToggleOpenForLibrary, setIsToggleOpenForLibrary] = useState(false);

  const handleToggleClickForUploadDocument = () => {
    setIsToggleOpenForUploadDocument(!isToggleOpenForUploadDocument);
    setIsToggleOpenForLibrary(false);
  };

  const handleToggleClickForLibrary = () => {
    setIsToggleOpenForLibrary(!isToggleOpenForLibrary);
    setIsToggleOpenForUploadDocument(false);
  };

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    setIsDraggingDoc(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDraggingDoc(false);
  }, []);

  const handleUploadedDocumentSelected = useCallback(
    (file: File) => {
      if (file) {
        setSelectedFile(file);
        const document: TemplateDocument = {
          name: file.name,
          type: getFileType(file),
          text: '',
          path: '', // Update with correct path if necessary
        };
        dispatch(setUploadedDocument(document));
      }
    },
    [dispatch]
  );

  const handleDeleteTemplate = useCallback(
    (e: React.MouseEvent, id: string) => {
      e.preventDefault();
      console.log(id);
      if (id) {
        dispatch(sendDeleteTemplateRequest(id));
      }
    },
    [dispatch]
  );

  const sendDocumentToBackend = async () => {
    console.log('Sending file to BE: ', uploadedDocument);
    const resultAction = await dispatch(uploadDocument(selectedFile));

    if (uploadDocument.fulfilled.match(resultAction)) {
      const newDocument = resultAction.payload;
      dispatch(sendCreateTemplateRequest(newDocument));
    } else {
      console.error('Failed to upload document:', resultAction.payload);
    }
  };

  const handleDropDocument = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDraggingDoc(false);
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        setSelectedFile(file);
        const document: TemplateDocument = {
          name: file.name,
          type: getFileType(file),
          // size: file.size,
          text: '',
          path: '', // Update with correct path if necessary
        };
        dispatch(setUploadedDocument(document));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (draftError) {
      dispatch(setIsLoadingDraft(false));
      dispatchToast(
        <Toast>
          <ToastTitle>Error: {draftError.message}</ToastTitle>
        </Toast>,
        { position: 'bottom', intent: 'error' }
      );
    }
  }, [draftError]);

  useEffect(() => {
    if (uploadError) {
      dispatchToast(
        <Toast>
          <ToastTitle>Error: {uploadError.message}</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'error' }
      );
    }
  }, [uploadError]);

  useEffect(() => {
    if (uploadedDocument) {
      dispatchToast(
        <Toast>
          <ToastTitle>Document ready to be processed</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'info' }
      );
    }
  }, [uploadedDocument]);

  const onHandleReturn = () => {
    return null;
  };

  const draftLoadingSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Generating draft"
    />
  );

  const uploadSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Uploading document"
    />
  );

  const deleteSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Deleting Template"
    />
  );

  return (
    <div>
      <DraftChat sendPayload={startDraftGeneration} />
      {/* <div className={customStyles.sectionContainerRow}>
        <div
          className={customStyles.actionButton}
          onClick={handleToggleClickForUploadDocument}
        >
          Upload Document
        </div>
        <div
          className={customStyles.actionButton}
          onClick={handleToggleClickForLibrary}
        >
          Choose From Library
        </div>
      </div> */}
      {/* {isToggleOpenForLibrary && (
        <Dropdown
          clearable
          appearance="underline"
          className={customStyles.templateDropdown}
          placeholder="Search templates"
          size="medium"
          onOptionSelect={onTemplateSelect}
        >
          {isLoadingListTemplate ? (
            <Spinner label="Loading Templates..." />
          ) : (
            <>
              {templateDocuments.map((template: TemplateDocument) => (
                <Option
                  key={template.id}
                  value={template.id}
                  text={template.name}
                >
                  <div className={customStyles.templateDocContainer}>
                    {getFileIcon(template.type, customStyles)}
                    <div className={customStyles.templateDocName}>
                      {template.name}
                    </div>
                    <div className={customStyles.templateDeleteIconContainer}>
                      <DismissRegular
                        className={customStyles.templateDeleteIcon}
                        onClick={e => handleDeleteTemplate(e, template.id)}
                      />
                    </div>
                  </div>
                </Option>
              ))}
            </>
          )}
        </Dropdown>
      )}
      {isToggleOpenForUploadDocument && (
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDropDocument}
          className={`${customStyles.dragDropArea} ${
            isDraggingDoc ? 'dragging' : ''
          }`}
        >
          {uploadedDocument ? (
            <>
              <div className={customStyles.sectionHeadingSmall}>
                {uploadedDocument.name}
              </div>
              <Button
                appearance="transparent"
                size="medium"
                onClick={sendDocumentToBackend}
              >
                Upload
              </Button>
              <Button
                appearance="transparent"
                size="small"
                onClick={() => dispatch(setUploadedDocument(null))}
              >
                Remove
              </Button>
            </>
          ) : (
            <>
              <p>Drag and Drop File</p>
              <p>Or</p>
              <FileUploader onFileSelect={handleUploadedDocumentSelected} />
            </>
          )}
        </div>
      )} */}
      <Button
        className={customStyles.generateDraftButton}
        onClick={startDraftGeneration}
        disabled={
          (isGeneratingDraft !== null && isGeneratingDraft) ||
          !isEssentialInformationComplete
        }
        style={{
          backgroundColor:
            isGeneratingDraft ||
            isLoadingListMandatoryQuestions ||
            !isEssentialInformationComplete
              ? '#A9A9A9'
              : '#0078D4',
        }}
      >
        Generate Draft
      </Button>
    </div>
  );
};
// todo relocate to DraftPage.modules.css
const useStyles = makeStyles({
  sectionHeading: {
    maxWidth: '230px',
    marginBottom: '16px',
    textAlign: 'center',
    fontSize: '18px',
    color: '#626262',
    marginRight: '32px',
  },
  infoButton: {
    position: 'absolute',
    right: '14px',
    top: '16px',
    color: '#919191',
  },
  promptInput: {
    width: '100%',
    height: '90px',
    backgroundColor: 'ghostwhite',
    ...shorthands.borderRadius('5px'),
    ...shorthands.border('1px', 'solid', '#DFDFDF'),
  },
  submitPromptButton: {
    position: 'absolute',
    right: '18px',
    bottom: '18px',
  },
  horizontalSectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  sectionHeadingSmall: {
    fontSize: '14px',
    color: '#626262',
  },
  sectionHeadingIcon: {
    marginRight: '5px',
    color: '#6C6C6C',
  },
  roleDropdown: {
    marginBottom: '16px',
    marginTop: '16px',
  },
  templateDropdown: {
    marginBottom: '16px',
    marginTop: '36px',
  },
  // spinner styles
  loader: {
    marginTop: '120px',
  },
  back: {
    marginLeft: '5px',
  },
  // draft generate styles
  greenTick: {
    width: '16px',
    marginBottom: '15px',
  },
  acceptButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...shorthands.gap('9px'),
  },
  acceptButton: {
    backgroundColor: '#0387F5',
  },
  acceptIcon: {
    color: '#848484',
    ':hover': {
      cursor: 'pointer',
    },
  },
  // template styles
  templateDocContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '200px',
  },
  templateDeleteIconContainer: {
    minWidth: '10px',
    ':hover': {
      '& $templateDeleteIcon': {
        transform: 'rotate(180deg)',
      },
    },
  },
  templateDeleteIcon: {
    ':hover': {
      transform: 'rotate(180deg)',
    },
    ...shorthands.transition('transform', '0.3s', 'ease-in-out'),
  },
  templateDocName: {
    marginLeft: '10px',
    fontSize: '14px',
    color: '#787878',
    width: '165px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // flex: 1
  },
  fileTypeIcon: {
    height: '14px',
  },

  // drag and drop
  dragDropArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    marginTop: '24px',
    ...shorthands.padding('20px'),
    ...shorthands.border('2px', 'dashed', '#8BBEEC'),
    ...shorthands.borderRadius('8px'),
    color: '#5A99E4',
    backgroundColor: '#EEF3F8',

    // Visual feedback for dragging over
    '&:hover, &.dragging': {
      ...shorthands.borderColor('#0078D4'), // Use primary color for border
      backgroundColor: '#F3F2F1', // Slightly darker background on hover/drag
      cursor: 'copy', // Cursor indicates an action
    },
  },
  baseDocUploadSuccessContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  greenTickBaseDoc: {
    width: '16px',
    marginRight: '10px',
  },
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('16px'),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    ...shorthands.border('1px', 'solid', '#DFDFDF'),
    backgroundColor: '#fff',
  },
  sectionContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
    height: '40px',
    backgroundColor: '#F3F2F1',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#E1E1E1',
    },
  },
  chatBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '200px',
    marginBottom: '16px',
    overflowY: 'auto',
    backgroundColor: '#FFFFFF',
  },
  chatInput: {
    width: '100%',
    height: '40px',
    marginTop: '10px',
  },
  userMessage: {
    alignSelf: 'flex-end',
    color: '#0078D4',
  },
  botMessage: {
    alignSelf: 'flex-start',
    color: '#626262',
  },
  generateDraftButton: {
    width: '100%',
    height: '40px',
    backgroundColor: '#0078D4',
    color: '#FFFFFF',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#005A9E',
    },
  },
});
