import { BaseRequest, BaseResponse, ResponseEnum } from './base';

export enum MessageRole {
  User = 'user',
  Assistant = 'assistant',
  Attach = "attach"
}

export interface ChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  attached: string;
}

export interface ChatWordRequest extends BaseRequest {
  messages: ChatMessage[];
  thread_id: string;
  open_document_body?: string;
}

export interface ChatWordResponse extends BaseResponse {
  response: string;
  thread_id: string;
}

export interface ChatWordResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
}

export const isChatWordResponse = (
  response: any
): response is ChatWordResponse => {
  return response.action === ResponseEnum.ChatWordResponse;
};

export const isChatWordResponseChunk = (
  response: any
): response is ChatWordResponseChunk => {
  return response.action === ResponseEnum.ChatWordResponseChunk;
};
