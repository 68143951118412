import { v4 as uuidv4 } from 'uuid';

import { AppDispatch } from '../store/store';
import { sendMessage } from '../store/socketSlice';

import { arrayBufferToBase64, getFileType } from './misc';

import { setUploadedDocument } from '../store/draftSlice';

import { DocumentType } from '../models/draft';
import { AttachmentDocumentSlice } from '../models/attachment';

export function getFileAsync(
  fileType: Office.FileType,
  options: Office.GetFileOptions
): Promise<Office.File> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(fileType, options, result => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

export function loadFileName(): Promise<string> {
  return new Promise(resolve => {
    Office.context.document.getFilePropertiesAsync(null, res => {
      console.log('File properties:', { res });
      if (res && res.value && res.value.url) {
        //let name = res.value.url.substr(res.value.url.lastIndexOf('\\') + 1);
        resolve(res.value.url.split('st=')[0].replace('?', ''));
      }
      resolve('');
    });
  });
}

export function getSliceAsync(
  file: Office.File,
  sliceIndex: number
): Promise<Office.Slice> {
  return new Promise((resolve, reject) => {
    file.getSliceAsync(sliceIndex, result => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

export function closeAsync(file: Office.File): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    file.closeAsync(result => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve();
      } else {
        reject(result.error);
      }
    });
  });
}

export async function sendSlice(slice: Office.Slice, state, dispatch) {
  try {
    const fileData = await arrayBufferToBase64(slice.data);

    const attachmentSlice: AttachmentDocumentSlice = {
      id: uuidv4(),
      index: slice.index,
      content: fileData,
      isFinalSlice: state.counter === state.sliceCount - 1,
    };

    dispatch(
      sendMessage({
        action: 'AttachmentDocumentSliceRequest',
        ...attachmentSlice,
      })
    );

    state.counter++;

    if (state.counter < state.sliceCount) {
      const nextSlice = await getSliceAsync(state.file, state.counter);
      await sendSlice(nextSlice, state, dispatch);
    } else {
      await closeAsync(state.file);
      console.log('File transmission completed and file closed.');
    }
  } catch (error) {
    console.log(`Sending slice failed: ${error.message}`);
  }
}
