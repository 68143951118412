import React, { useEffect, useRef } from 'react';
import styles from './BotChating.module.css';
import { MessageRole } from '../../../models/chat';
import { Image, Text, Textarea, TextareaProps } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Send16Filled } from '@fluentui/react-icons';
import { TypingMarkdownRenderer } from '../misc/TypingMarkdownRenderer';
import { BatteryProgressBar, LoadingAnimation } from 'qanoonisharedpackage';

// component location taskpane\components\botChating\BotChating.tsx
// examples of this chatbot component usage you can find in
//- taskpane/components/tabs/reviewTab/components/SuggestChat.tsx
//- taskpane/components/tabs/globalChanges/GlobalChange.tsx
//- taskpane/components/tabs/reviewTab/components/Chat.tsx

// note you can add any special action of your bot follow example of is_yes_no or is_apply_regenrate
// note please ping me before make any changes so It doesn't effect other sections use the component
type Props = {
  height?: string; //height of the chat
  value: string; //state for textarea input
  setValue: React.Dispatch<React.SetStateAction<string>>; //setstate for the textarea input
  chatMessages: any[]; //chat messages history
  onMessageSend: any; // on click send icon
  onYes?: any; // if yes no question on click yes
  onNo?: any; // if yes no question on click no
  onApply?: any; // if apply regenrate question on click apply
  isShowProgressBar?: boolean;
  progressBarValue?: number;
  isShowProgressBarLabel?: boolean;
  progressBarLabel?: string;
  onRegenrate?: any; // if apply regenrate question on click regenrate
  classNameCnt?: string; //class name of the container if you want to mkae your own styles
  loading?: boolean;
  typingDelay?: number; //animation type typingDelay
};
interface ChatMessageType {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  is_yes_no?: boolean;
  is_apply_regenrate?: boolean;
  is_dropdown?: boolean;
}

const BotChating = ({
  classNameCnt,
  height,
  chatMessages,
  onMessageSend,
  value,
  setValue,
  onYes,
  onNo,
  onApply,
  onRegenrate,
  isShowProgressBar,
  progressBarValue,
  isShowProgressBarLabel,
  progressBarLabel,
  loading,
  typingDelay,
}: Props) => {
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const bottomRef = useRef(null);
  const onKeyDown: TextareaProps['onKeyDown'] = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onMessageSend();
    }
  };
  //on text change in textarea
  const onChange: TextareaProps['onChange'] = (_ev, data) => {
    setValue(data.value);
  };

  //scroll down on new message
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  return (
    <div>
      <div
        className={
          classNameCnt ? `${styles.chatCnt} ${classNameCnt}` : styles.chatCnt
        }
        style={{ height: height ? height : undefined }}
      >
        <div className={styles.messagesCnt}>
          {chatMessages.map((chatMessage: ChatMessageType) => {
            if (chatMessage.role === 'user') {
              return (
                <div className={styles.messageCnt} key={chatMessage.id}>
                  <div className={styles.userMessage}>
                    <TypingMarkdownRenderer
                      markdown={chatMessage.message}
                      animate={false}
                    />
                  </div>
                  <Image
                    src="/assets/profile.png"
                    className={styles.userLogo}
                  />
                </div>
              );
            } else {
              return (
                <div className={styles.messageCnt2} key={chatMessage.id}>
                  <div
                    className={styles.aiMessage}
                    style={
                      chatMessage.message === '...'
                        ? undefined
                        : { minHeight: '50px' }
                    }
                  >
                    {chatMessage.message === '...' ? (
                      <LoadingAnimation />
                    ) : (
                      <>
                        {!chatMessage.isChunk && (
                          <TypingMarkdownRenderer
                            markdown={chatMessage.message}
                            animate={true}
                            onComplete={() => {
                              bottomRef.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }}
                            typingDelay={typingDelay}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className={styles.aiLogo}>
                    {' '}
                    <Image src="/assets/white-logo.png" />
                  </div>
                  {chatMessage.is_yes_no && !chatMessage.isChunk && (
                    <div className={styles.yesnoCnt}>
                      <div
                        className={styles.yesno}
                        onClick={() => {
                          onYes();
                        }}
                      >
                        Yes
                      </div>
                      <div
                        className={styles.yesno}
                        onClick={() => {
                          onNo();
                        }}
                      >
                        No
                      </div>
                    </div>
                  )}
                  {chatMessage.is_apply_regenrate && !chatMessage.isChunk && (
                    <div className={styles.RegenrateCnt}>
                      <div
                        className={styles.yesno}
                        onClick={() => {
                          onApply();
                        }}
                      >
                        Apply to current
                      </div>
                      <div
                        className={styles.yesno}
                        onClick={() => {
                          onRegenrate();
                        }}
                      >
                        Regenerate
                      </div>
                    </div>
                  )}
                  {chatMessage.is_dropdown && (
                    <div className={styles.RegenrateCnt}>
                      Put your dropdown here
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
        {isShowProgressBar && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
              <BatteryProgressBar
                value={progressBarValue}
                width={'200px'}
                height={30}
              />
            </div>
            {isShowProgressBarLabel && (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
                <Text> {progressBarLabel} </Text>
              </div>
            )}
          </div>
        )}
        <div className={styles.sendCnt}>
          <Textarea
            appearance="outline"
            placeholder="Write here ...."
            className={styles.sendArea}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
          ></Textarea>
          <div
            className={styles.iconCnt}
            style={{
              cursor: isConnected && !loading ? 'pointer' : 'not-allowed',
            }}
          >
            <Send16Filled
              className={styles.sendIcon}
              onClick={() => {
                if (!loading) {
                  onMessageSend();
                }
              }}
            />
          </div>
          <div ref={bottomRef}></div>
        </div>
      </div>
    </div>
  );
};

export default BotChating;
