import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage, MessageRole } from '../../../models/chat';
import Tesseract from 'tesseract.js';
import {
  sendChatMessage,
  removeChatMessage,
  setStopStreaming,
  setIsStreaming,
  addAttachMessage,
  setChatMessages,
} from '../../../store/qcounselSlice';
import { sendFeedback } from '../../../store/socketSlice';
import { AppDispatch, RootState } from '../../../store/store';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import { RequestEnum } from '../../../models/base';
import { UserOfflineProcess } from '../../../models/userActivity';
import { UserFeedbackRequest } from '../../../models/feedback';
import { QCounsel } from 'qanoonisharedpackage';

export const ChatContainer: React.FC = () => {
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const dispatch = useDispatch<AppDispatch>();
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [chatId, setChatId] = useState<string>(null); // Add loading state

  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.qcounsel.chatMessages
  );
  const isStreaming: boolean = useSelector(
    (state: RootState) => state.qcounsel.isStreaming
  );
  const userOfflineProcess: UserOfflineProcess = useSelector(
    (state: RootState) => state.activity.userOfflineProcess
  );
  const [failure, setfailure] = useState(false);
  // Style for the outer white circle with shadow
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleRegenerate = async (messageId: string) => {
    console.log('regenerate pressed');
    setIsRegenerating(true);

    // Find the index of the message to regenerate
    const messageIndex = chatMessages.findIndex(msg => msg.id === messageId);
    if (messageIndex === -1) {
      console.error('Message not found');
      setIsRegenerating(false);
      return;
    }

    // Remove the message and all subsequent messages
    for (let i = chatMessages.length - 1; i >= messageIndex; i--) {
      dispatch(removeChatMessage(chatMessages[i].id));
    }

    // Get the last user message before the removed message
    let lastUserMessage = '';
    for (let i = messageIndex - 1; i >= 0; i--) {
      if (chatMessages[i].role === MessageRole.User) {
        lastUserMessage = chatMessages[i].message;
        break;
      }
    }

    // Regenerate the response
    let chat_id = chatId;
    if (!chat_id) {
      chat_id = uuidv4();
      setChatId(chat_id);
    }
    try {
      dispatch(
        sendChatMessage({
          newMessage: {
            id: uuidv4(),
            message: lastUserMessage,
            role: MessageRole.User,
            isChunk: false,
            attached: null,
            chat_id: chat_id,
          },
          add_current: false,
        })
      );
    } catch (error) {
      console.error('Error regenerating message:', error);
    }

    setIsRegenerating(false);
  };

  const sendFeedbackGet = (
    feedbackType: 'thumbs_up' | 'thumbs_down',
    feedbackContent: string
  ) => {
    console.log('feedback send function starts', feedbackType);
    const request: UserFeedbackRequest = {
      action: RequestEnum.UserFeedbackRequest,
      feature_type: 'QCouncel chat',
      feature_ref_id: null, //featureRefId,
      feedback: feedbackType,
      trace_id: null, //traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);

    dispatch(sendFeedback(request));
    console.log('Sent FeedbackGetRequest to server', request);
  };

  // Configure pdfjs to not use a worker

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      setLoading(true); // Show spinner when processing starts
      setfailure(false);
      try {
        if (fileExtension === 'txt') {
          // Handle .txt files directly
          const text = await selectedFile.text();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'docx') {
          // Handle .docx files
          const arrayBuffer = await selectedFile.arrayBuffer();
          const zip = new PizZip(arrayBuffer);
          const doc = new Docxtemplater(zip);
          const text = doc.getFullText();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'pdf') {
          // Handle .pdf files
          console.log('Processing PDF file');
          const arrayBuffer = await selectedFile.arrayBuffer();
          const uint8Array = new Uint8Array(arrayBuffer);
          const pdf = await pdfjsLib.getDocument({
            data: uint8Array,
            worker: null,
          }).promise;
          let text = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            let pageText = textContent.items
              .map((item: any) => ('str' in item ? item.str : ''))
              .join(' ');

            // If the extracted text is empty, attempt OCR
            if (!pageText.trim()) {
              console.log(`Page ${i} has no selectable text, using OCR...`);
              const scale = 2;
              const viewport = page.getViewport({ scale });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.width = viewport.width;
              canvas.height = viewport.height;

              const renderContext = { canvasContext: context, viewport };
              await page.render(renderContext).promise;

              // Use Tesseract.js to perform OCR with Arabic and English support
              const ocrResult = await Tesseract.recognize(
                canvas,
                'eng+ara', // Support both English and Arabic
                {}
              );
              pageText = ocrResult.data.text;
            }

            text += pageText + '\n';
          }

          // If no text was found using both methods, show an error message
          if (!text.trim()) {
            console.error(
              'Error: PDF contains no selectable or extractable text.'
            );
            addAttachMessage({
              id: uuidv4(),
              message: `Failed to extract text from ${selectedFile.name}. Both native text extraction and OCR failed. Please ensure the PDF is not corrupted or password-protected.`,
              role: MessageRole.Attach,
              isChunk: false,
              attached: null,
            });
            return;
          }

          setFileContent(text);
        } else {
          // Handle unsupported file extensions
          console.log(`Unsupported file type: ${fileExtension}`);

          dispatch(
            addAttachMessage({
              id: uuidv4(),
              message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
              role: MessageRole.Attach,
              isChunk: false,
              attached: null,
            })
          );
          return; // Exit the function if the file type is unsupported
        }

        dispatch(
          addAttachMessage({
            id: uuidv4(),
            message: ` ${selectedFile.name}`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          })
        );
        console.log('attach message sent');
      } catch (error) {
        console.error('Error processing file:', error);
        setfailure(true);
        setTimeout(() => {
          setfailure(false);
        }, 2000);
        setLoading(false);
      } finally {
        setLoading(false); // Hide spinner when processing completes
      }
    }
  };

  const onMessageSend = (value: string) => {
    let chat_id = chatId;
    if (!chat_id) {
      chat_id = uuidv4();
      setChatId(chat_id);
    }
    dispatch(
      sendChatMessage({
        newMessage: {
          chat_id: chat_id,
          id: uuidv4(),
          message: value,
          role: MessageRole.User,
          isChunk: false,
          attached: fileContent ? fileContent : null,
        },
        add_current: true,
      })
    );
    dispatch(setStopStreaming(false));
  };
  return (
    <div>
      <QCounsel
        isSocketReady={isConnected}
        userOfflineProcess={userOfflineProcess}
        chatMessages={chatMessages}
        sendFeedbackGet={sendFeedbackGet}
        onMessageSend={onMessageSend}
        handleFileChange={handleFileChange}
        loading={loading}
        failure={failure}
        setFileContent={setFileContent}
        handleRegenerate={handleRegenerate}
        isStreaming={isStreaming}
        stopResponding={() => {
          dispatch(setStopStreaming(true));
          dispatch(setIsStreaming(false));
        }}
        clearMessage={() => {
          dispatch(setChatMessages([]));
        }}
        sessionId=""
        setSessionId={() => {}}
        posthog={null}
        user_id=""
      />
    </div>
  );
};
