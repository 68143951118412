import * as React from 'react';
import styles from './ReviewProgress.module.css';
import { ProgressBar } from '@fluentui/react-components';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

export const ReviewProgress = () => {
  const suggestions = useSelector(
    (state: RootState) => state.office.suggestions
  );
  const suggestionsToConsider = suggestions.filter(
    suggestion => suggestion.user_suggestion_action !== 'deleted'
  );
  const actionedSuggestions = suggestionsToConsider.filter(
    suggestion => suggestion.user_suggestion_action === 'accepted'
  );
  const progress =
    (actionedSuggestions.length / suggestionsToConsider.length) * 100;
  console.log(
    'progress',
    progress,
    actionedSuggestions,
    actionedSuggestions.length,
    suggestionsToConsider.length
  );
  return (
    <div className={styles.container}>
      <div>Correction progress</div>
      <div className={styles.progressContainer}>
        <ProgressBar
          shape="rounded"
          className={styles.progressBar}
          thickness="large"
          value={actionedSuggestions.length}
          max={suggestionsToConsider.length || 1} // Ensure max is never zero
        />
        <div className={styles.percentage}>
          {progress ? `${progress.toFixed(0)}%` : '0%'}
        </div>
      </div>
    </div>
  );
};
