import React from 'react';

import { DocumentType } from '../models/draft';

import { Image } from '@fluentui/react-components';

export const getFileIcon = (
  docType: DocumentType,
  styles: Record<any, any>
) => {
  switch (docType) {
    case DocumentType.Word:
      return (
        <Image
          className={styles.fileTypeIcon}
          src={'assets/word_icon.png'}
          alt="Word Icon"
        />
      );

    case DocumentType.PDF:
      return (
        <Image
          className={styles.fileTypeIcon}
          src={'assets/pdf_icon.png'}
          alt="PDF Icon"
        />
      );

    default:
      return null;
  }
};

export function getFileType(file: File): DocumentType {
  switch (file.type) {
    case 'application/pdf':
      return DocumentType.PDF;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return DocumentType.Word;
    case 'application/msword':
      return DocumentType.Word;
    default:
      return DocumentType.Unknown;
  }
}

export function arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string; // Cast to string because reader.result can be null|string|ArrayBuffer
      resolve(result.split(',')[1]); // Split and take the Base64 part
    };
    reader.onerror = () =>
      reject(new Error('Failed to read the blob as a data URL'));
    reader.readAsDataURL(blob);
  });
}
