import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  Image,
  InfoLabel,
  makeStyles,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  shorthands,
  Spinner,
  Textarea,
  TextareaProps,
  Toast,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components';
import {
  ArrowCircleUp24Filled,
  ArrowUpSquareSettings24Regular,
  Board24Regular,
  Checkmark24Regular,
  ThumbLike24Regular,
  DismissRegular
} from '@fluentui/react-icons';

import PageContainer from '../../PageContainer';
import { GLOBAL_TOAST_ID, Layout } from '../Layout';
import FileUploader from './FileUploader';

import { AppDispatch, RootState } from '../../../../store/store';
import {
  sendCreateTemplateRequest,
  sendDraftRequest,
  SendDraftRequestPayload,
  setUploadedDocument,
  setHasGeneratedDraft,
  setIsLoadingDraft,
  uploadDocument,
  sendDeleteTemplateRequest,
} from '../../../../store/draftSlice';
import { sendAttachmentDocumentRequest } from '../../../../store/officeSlice';

import { TemplateDocument, DocumentType } from '../../../../models/draft';
import { LeftArrowIcon } from '../../../../assets/LeftArrowIcon';
import { getFileIcon, getFileType } from '../../../../helper/misc';

import { useNavigate } from 'react-router-dom';
import styles from './DraftPage.module.css';
import { divProperties } from '@fluentui/react';

export const DraftPage: React.FC = () => {
  const customStyles = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [draftRole, setDraftRole] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const hasGeneratedDraft: boolean = useSelector(
    (state: RootState) => state.draft.hasGeneratedDraft
  );
  const isLoadingDraft: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingDraft
  );
  const draftError: Error = useSelector(
    (state: RootState) => state.draft.draftError
  );
  const templateDocuments: TemplateDocument[] = useSelector(
    (state: RootState) => state.draft.templateDocuments
  );
  // const uploadedDocument = useSelector((state: RootState) => state.draft.uploadedDocument);
  const uploadError: Error = useSelector(
    (state: RootState) => state.draft.uploadError
  );

  const draftRoles = [
    { label: 'Assignee', value: 'Assignee' },
    { label: 'Assignor', value: 'Assignor' },
  ];
  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);

  const [prompt, setPrompt] = React.useState('');
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateDocument | null>(null);

  // file upload
  const isLoadingUploadedDocument: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingUploadedDocument
  );
  const isLoadingListTemplate: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingListTemplate
  );
  const isLoadingDeleteTemplate: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingDeleteTemplate
  );
  const [isDraggingDoc, setIsDraggingDoc] = React.useState(false);
  const uploadedDocument = useSelector(
    (state: RootState) => state.draft.uploadedDocument
  );

  const onPromptChange: TextareaProps['onChange'] = (_ev, data) => {
    setPrompt(data.value);
  };

  const onTemplateSelect = (
    _event: SelectionEvents,
    data: OptionOnSelectData
  ): void => {
    const templateId = data.optionValue;
    const templateDocument = templateDocuments.find(
      template => template.id === templateId
    );
    console.log('Selected template:', templateDocument);
    setSelectedTemplate(templateDocument || null);
  };

  const sendDocumentAsAttachment = () => {
    console.log('Sending document as attachment');
    dispatch(sendAttachmentDocumentRequest());
  };

  const sendPayload = () => {
    if (!draftRole) {
      dispatchToast(
        <Toast>
          <ToastTitle>Please select a role</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'error' }
      );
      return;
    }

    if (!prompt) {
      dispatchToast(
        <Toast>
          <ToastTitle>Please enter a prompt</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'error' }
      );
      return;
    }
    const payload: SendDraftRequestPayload = {
      prompt,
      template: selectedTemplate,
      draftRole: draftRole,
    };
    dispatch(sendDraftRequest(payload));
  };

  const restartDraftGeneration = () => {
    console.log('Restarting draft generation');
    setPrompt('');
    dispatch(setHasGeneratedDraft(false));
  };

  // FILE UPLOAD LOGIC

  const [isToggleOpen, setIsToggleOpen] = useState(false);

  const handleToggleClick = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    setIsDraggingDoc(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDraggingDoc(false);
  }, []);

  const handleUploadedDocumentSelected = useCallback((file: File) => {
    if (file) {
      setSelectedFile(file);
      const document: TemplateDocument = {
        name: file.name,
        type: getFileType(file),
        text: '',
        path: '', // Update with correct path if necessary
      };
      dispatch(setUploadedDocument(document));
    }
  }, [dispatch]);

  const handleDeleteTemplate = useCallback((e: React.MouseEvent, id: string) => {
    e.preventDefault();
    console.log(id);
    if (id) {
      dispatch(sendDeleteTemplateRequest(id))
    }
  }, [dispatch])

  const sendDocumentToBackend = async () => {
    console.log('Sending file to BE: ', uploadedDocument);
    const resultAction = await dispatch(uploadDocument(selectedFile));

    if (uploadDocument.fulfilled.match(resultAction)) {
      const newDocument = resultAction.payload;
      dispatch(sendCreateTemplateRequest(newDocument));
    } else {
      console.error('Failed to upload document:', resultAction.payload);
    }
  };

  const handleDropDocument = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingDoc(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      setSelectedFile(file)
      const document: TemplateDocument = {
        name: file.name,
        type: getFileType(file),
        // size: file.size,
        text: '',
        path: '', // Update with correct path if necessary
      };
      dispatch(setUploadedDocument(document));
    }
  }, [dispatch]);

  useEffect(() => {
    if (draftError) {
      dispatch(setIsLoadingDraft(false));
      dispatchToast(
        <Toast>
          <ToastTitle>Error: {draftError.message}</ToastTitle>
        </Toast>,
        { position: 'bottom', intent: 'error' }
      );
    }
  }, [draftError]);

  useEffect(() => {
    if (uploadError) {
      dispatchToast(
        <Toast>
          <ToastTitle>Error: {uploadError.message}</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'error' }
      );
    }
  }, [uploadError])

  useEffect(() => {
    if (uploadedDocument) {
      dispatchToast(
        <Toast>
          <ToastTitle>Document ready to be processed</ToastTitle>
        </Toast>,
        { position: 'top', intent: 'info' }
      );
    }
  }, [uploadedDocument])

  const draftLoadingSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Generating draft"
    />
  );

  const uploadSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Uploading document"
    />
  );

  const deleteSpinner = (
    <Spinner
      className={customStyles.loader}
      labelPosition="below"
      label="Deleting Template"
    />
  );



  const createDraftComponent = (
    <PageContainer className={customStyles.container}>
      <div className={styles.heading}>Draft a document</div>
      <div className={customStyles.sectionContainer}>
        <Dropdown
          clearable
          appearance="underline"
          className={customStyles.roleDropdown}
          placeholder="Select a role"
          size="medium"
          value={draftRole}
          onOptionSelect={(_, data) => setDraftRole(data.optionValue)}
        >
          {draftRoles.map(role => (
            <Option key={role.value} value={role.value}>
              {role.label}
            </Option>
          ))}
        </Dropdown>
      </div>
      <div className={customStyles.sectionContainer}>
        <InfoLabel
          size="medium"
          info="Type your prompt to generate a draft"
          className={customStyles.infoButton}
        />
        <div className={customStyles.sectionHeading}>
          Generate Base Document
        </div>

        <Textarea
          className={customStyles.promptInput}
          placeholder={
            'Describe the details of the agreement or document that you wish to generate as a base document'
          }
          onChange={onPromptChange}
          value={prompt}
          appearance="outline"
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendPayload();
            }
          }}
        />
        <Button
          appearance="transparent"
          icon={<ArrowCircleUp24Filled />}
          size="large"
          onClick={sendPayload}
          className={customStyles.submitPromptButton}
        />
      </div>
      <div className={customStyles.sectionContainer}>
        <div
          className={customStyles.horizontalSectionTitle}
          onClick={handleToggleClick}
        >
          <InfoLabel
            size="medium"
            info="Drag and drop your document to upload a new template"
            className={customStyles.infoButton}
          />
          <ArrowUpSquareSettings24Regular
            className={customStyles.sectionHeadingIcon}
          />
          <div className={customStyles.sectionHeadingSmall}>
            Upload New Template
          </div>
        </div>
        {isToggleOpen && (
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDropDocument}
            className={`${customStyles.dragDropArea} ${isDraggingDoc ? 'dragging' : ''
              }`}
          >
            {uploadedDocument ? (
              <>
                <div className={customStyles.sectionHeadingSmall}>
                  {uploadedDocument.name}
                </div>
                <Button
                  appearance="transparent"
                  size="medium"
                  onClick={sendDocumentToBackend}
                >
                  Upload
                </Button>
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={() => dispatch(setUploadedDocument(null))}
                >
                  Remove
                </Button>
              </>
            ) : (
              <>
                <p>Drag and Drop File</p>
                <p>Or</p>
                <FileUploader onFileSelect={handleUploadedDocumentSelected} />
              </>
            )}
          </div>

        )}
        {isLoadingUploadedDocument && <Spinner label="Uploading document..." />}

      </div>
      <div className={customStyles.sectionContainer}>
        <div className={customStyles.horizontalSectionTitle}>
          <InfoLabel
            size="medium"
            info="Choose a suitable template to generate from"
            className={customStyles.infoButton}
          />
          <Board24Regular className={customStyles.sectionHeadingIcon} />
          <div className={customStyles.sectionHeadingSmall}>
            Choose From Template Library
          </div>
        </div>
        <Dropdown
          clearable
          appearance="underline"
          className={customStyles.templateDropdown}
          placeholder="Search templates"
          size="medium"
          onOptionSelect={onTemplateSelect}
        >
          {isLoadingListTemplate ? (
            <Spinner label="Loading Templates..." />
          ) : (
            <>
              {
                templateDocuments.map((template: TemplateDocument) => (
                  <Option key={template.id} value={template.id} text={template.name}>
                    <div className={customStyles.templateDocContainer}>
                      {getFileIcon(template.type, customStyles)}
                      <div className={customStyles.templateDocName}>
                        {template.name}
                      </div>
                      <div className={customStyles.templateDeleteIconContainer}>
                        <DismissRegular className={customStyles.templateDeleteIcon} onClick={(e) => handleDeleteTemplate(e, template.id)} />
                      </div>
                    </div>
                  </Option>
                ))
              }
            </>
          )}

        </Dropdown>
      </div>
    </PageContainer>
  );

  const draftGeneratedComponent = (
    <PageContainer className={customStyles.container}>
      <div className={customStyles.sectionContainer}>
        <Image
          className={customStyles.greenTick}
          src={'assets/green_tick.png'}
          alt="Green tick icon"
        />
        <div className={customStyles.sectionHeading}>
          Your draft has been generated
        </div>
        <Textarea
          className={customStyles.promptInput}
          placeholder={
            'Describe the details of the agreement or document that you wish to generate as a base document'
          }
          onChange={onPromptChange}
          value={prompt}
          appearance="outline"
          disabled
        />
        <Button
          appearance="transparent"
          disabled
          icon={<ArrowCircleUp24Filled />}
          size="medium"
          className={customStyles.submitPromptButton}
        />
      </div>
      <div className={customStyles.acceptButtonContainer}>
        <Button
          className={customStyles.acceptButton}
          appearance="primary"
          icon={<Checkmark24Regular />}
          onClick={() => navigate('/review')}
        >
          Keep It
        </Button>
        <ThumbLike24Regular
          className={customStyles.acceptIcon}
          onClick={sendDocumentAsAttachment}
        />
        <ArrowCircleUp24Filled
          onClick={restartDraftGeneration}
          className={customStyles.acceptIcon}
        />
      </div>
    </PageContainer>
  );

  return (
    <Layout
      header={
        <div className={styles.toolbarContainer}>
          <div className={styles.analyzeButtonContainer}></div>
        </div>
      }
      footer={null}
    >
      {isLoadingDraft
        ? draftLoadingSpinner
        : isLoadingUploadedDocument
          ? uploadSpinner
          : isLoadingDeleteTemplate ?
            deleteSpinner
            : hasGeneratedDraft
              ? draftGeneratedComponent
              : createDraftComponent}
    </Layout>
  );
};

// todo relocate to DraftPage.modules.css
const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('16px'),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('8px'),
    ...shorthands.border('1px', 'solid', '#DFDFDF'), // Dark blue border
    backgroundColor: '#fff',
  },
  sectionHeading: {
    maxWidth: '230px',
    marginBottom: '16px',
    textAlign: 'center',
    fontSize: '18px',
    color: '#626262',
    marginRight: '32px',
  },
  infoButton: {
    position: 'absolute',
    right: '14px',
    top: '16px',
    color: '#919191',
  },
  promptInput: {
    width: '100%',
    height: '90px',
    backgroundColor: 'ghostwhite',
    ...shorthands.borderRadius('5px'),
    ...shorthands.border('1px', 'solid', '#DFDFDF'),
  },
  submitPromptButton: {
    position: 'absolute',
    right: '18px',
    bottom: '18px',
  },
  horizontalSectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  sectionHeadingSmall: {
    fontSize: '14px',
    color: '#626262',
  },
  sectionHeadingIcon: {
    marginRight: '5px',
    color: '#6C6C6C',
  },
  roleDropdown: {
    marginBottom: '16px',
    marginTop: '16px',
  },
  templateDropdown: {
    marginBottom: '16px',
    marginTop: '36px',
  },
  // spinner styles
  loader: {
    marginTop: '120px',
  },
  back: {
    marginLeft: '5px',
  },
  // draft generate styles
  greenTick: {
    width: '16px',
    marginBottom: '15px',
  },
  acceptButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...shorthands.gap('9px'),
  },
  acceptButton: {
    backgroundColor: '#0387F5',
  },
  acceptIcon: {
    color: '#848484',
    ':hover': {
      cursor: 'pointer',
    },
  },
  // template styles
  templateDocContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: "200px"
  },
  templateDeleteIconContainer: {
    minWidth: '10px',
    ':hover': {
      '& $templateDeleteIcon': {
        transform: 'rotate(180deg)',
      },
    }
  },
  templateDeleteIcon: {
    ':hover': {
      transform: 'rotate(180deg)',
    },
    ...shorthands.transition('transform', '0.3s', 'ease-in-out')
  },
  templateDocName: {
    marginLeft: '10px',
    fontSize: '14px',
    color: '#787878',
    width: '165px',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    // flex: 1
  },
  fileTypeIcon: {
    height: '14px',
  },

  // drag and drop
  dragDropArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    marginTop: '24px',
    ...shorthands.padding('20px'),
    ...shorthands.border('2px', 'dashed', '#8BBEEC'),
    ...shorthands.borderRadius('8px'),
    color: '#5A99E4',
    backgroundColor: '#EEF3F8',

    // Visual feedback for dragging over
    '&:hover, &.dragging': {
      ...shorthands.borderColor('#0078D4'), // Use primary color for border
      backgroundColor: '#F3F2F1', // Slightly darker background on hover/drag
      cursor: 'copy', // Cursor indicates an action
    },
  },
  baseDocUploadSuccessContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  greenTickBaseDoc: {
    width: '16px',
    marginRight: '10px',
  },
});
