import * as React from 'react';
import styles from './DebugModePage.module.css';
import { Divider, Button } from '@fluentui/react-components';
import { LeftArrowIcon } from '../../../assets/LeftArrowIcon';
import { useNavigate } from 'react-router-dom';
import { Layout } from './Layout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { AnnotationTask } from '../../../models/annotation';
import { sendAnnotationToQueue } from '../../../store/annotationSlice';
import { useState } from 'react';
import { RequestEnum } from '../../../models/base';
import { formatDistanceToNow } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const DebugModePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const annotations = useSelector((state: RootState) => state.annotation.annotations);
  const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean }>({});

  const handleSendToQueue = (annotation: AnnotationTask) => {
    console.log('Sending annotation to queue', annotation);
    setDisabledButtons(prevState => ({ ...prevState, [annotation.task_id]: true }));
    (dispatch as AppDispatch)(sendAnnotationToQueue({
      task_id: annotation.task_id,
      task_type: annotation.task_type,
      action: RequestEnum.SendToAnnotationQueueRequest
    }));
  };
  const handleTrace = (annotation: AnnotationTask) => {
    console.log('Tracing annotation', annotation);
    window.location.href = `https://smith.langchain.com/o/02bcc2ab-2d8c-4d9b-8e33-a33d2714599d/projects/p/58541fc9-b70e-4725-9547-e3488a3f3bd0?columnVisibilityModel=%7B%22feedback_stats%22%3Afalse%2C%22reference_example%22%3Afalse%2C%22metadata%22%3Atrue%7D&timeModel=%7B%22duration%22%3A%227d%22%7D&peek=${annotation.task_id}`;
    // Add your tracing logic here
  };

  return (
    <Layout
      header={
        <div className={styles.toolbarContainer}>
          <div id="nav" className={styles.back} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </div>
        </div>
      }
      footer={<></>}
    >
      <div className={styles.annotationText}>Analysis log</div>
      <div className={styles.container}>
        <div className={styles.annotationList}>
          {annotations
            .slice()
            .sort((a, b) => new Date(b.task_time).getTime() - new Date(a.task_time).getTime())
            .slice(0, 5)
            .map((annotation: AnnotationTask) => {
              const localTime = toZonedTime(new Date(annotation.task_time), Intl.DateTimeFormat().resolvedOptions().timeZone);
              console.log("Local time: ", localTime);
              console.log("Annotation time: ", annotation.task_time);
              return (
                <div key={annotation.task_id} className={`${styles.annotationItem} ${disabledButtons[annotation.task_id] ? styles.disabled : ''}`}>
                  <div className={styles.annotationDetails}>
                    <div><strong>Id:</strong> {annotation.task_id.slice(0, 16)}</div>
                    <div><strong>Type:</strong> {annotation.task_type}</div>
                    <div><strong>Time:</strong> {formatDistanceToNow(localTime, { addSuffix: true })}</div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button onClick={() => handleTrace(annotation)}>Open Tracing</Button>
                    <Button 
                      className={disabledButtons[annotation.task_id] ? styles.disabledButton : ''} 
                      disabled={disabledButtons[annotation.task_id]} 
                      onClick={() => handleSendToQueue(annotation)}
                    >
                      {disabledButtons[annotation.task_id] ? 'Queued' : 'Queue Review'}
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

