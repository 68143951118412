import { BaseRequest, BaseResponse, ResponseEnum } from './base';
import { EmailAttachment } from './openEmail';

export enum MessageRole {
  User = 'user',
  Assistant = 'assistant',
  Attach = 'attach',
}

export interface ChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  attached: string;
  docs?: string[];
  is_yes_no?: boolean;
  is_apply_regenrate?: boolean;
  is_dropdown?: boolean;
  chat_id?: string;
}
export interface ReviewChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  is_finished?: boolean;
  sessionId?: string;
  stepSessionId?: string;
  time?: string;
  user_id?: string;
}
export interface SuggestChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  is_question?: boolean;
  is_suggestion_message?: boolean;
  sessionId?: string;
  stepSessionId?: string;
  time?: string;
  user_id?: string;
}
export interface ChatEmailRequest extends BaseRequest {
  messages: ChatMessage[];
  thread_id: string;
  open_email_body?: string;
  attachments?: EmailAttachment[];
  is_regenrate?: boolean;
  chat_id?: string;
}
export interface ReviewDocumentChatRequest extends BaseRequest {
  messages: ReviewChatMessage[];
  thread_id: string;
  document_text?: string;
  document_id: string;
}
export interface ReviewDocumentSuggestChatRequest extends BaseRequest {
  messages: ReviewChatMessage[];
  thread_id: string;
  document_text?: string;
  current_clause?: string;
  ai_suggested_clause?: string;
}

export interface ChatEmailResponse extends BaseResponse {
  response: string;
  thread_id: string;
  docs: string[];
}
export interface ReviewDocumentChatResponse extends BaseResponse {
  response: string;
  thread_id: string;
  is_finished: boolean;
}
export interface SuggestAlternativeChatResponse extends BaseResponse {
  response: string;
  thread_id: string;
  is_question?: boolean;
  is_suggestion_message?: boolean;
}
export interface ReviewDocumentChatResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  is_finished: boolean;
}
export interface SuggestAlternativeChatResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  is_question?: boolean;
  is_suggestion_message?: boolean;
}
export interface ChatEmailResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  docs: string[];
}

export const isChatEmailResponse = (
  response: any
): response is ChatEmailResponse => {
  return response.action === ResponseEnum.ChatEmailResponse;
};

export const isChatEmailResponseChunk = (
  response: any
): response is ChatEmailResponseChunk => {
  return response.action === ResponseEnum.ChatEmailResponseChunk;
};

export const isReviewDocumentChatResponseChunk = (
  response: any
): response is ReviewDocumentChatResponseChunk => {
  return response.action === ResponseEnum.ReviewDocumentChatResponseChunk;
};
export const isReviewDocumentChatResponse = (
  response: any
): response is ReviewDocumentChatResponse => {
  return response.action === ResponseEnum.ReviewDocumentChatResponse;
};

export const isReviewDocumentSuggestAlternativeChatResponseChunk = (
  response: any
): response is SuggestAlternativeChatResponseChunk => {
  return response.action === ResponseEnum.SuggestAlternativeChatResponseChunk;
};
export const isReviewDocumentSuggestAlternativeChatResponse = (
  response: any
): response is SuggestAlternativeChatResponse => {
  return response.action === ResponseEnum.SuggestAlternativeChatResponse;
};
