import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationTask, SendToAnnotationQueueRequest } from '../models/annotation';
import { RootState, AppDispatch } from './store'; // Import AppDispatch
import { sendMessage } from './socketSlice';

interface AnnotationState {
  annotations: AnnotationTask[];
}

const initialState: AnnotationState = {
  annotations: [],
};

export const sendAnnotationToQueue = createAsyncThunk(
  'annotation/sendToQueue',
  async (request: SendToAnnotationQueueRequest, { dispatch, getState }) => {
    const state: RootState = getState() as RootState;
    (dispatch as AppDispatch)(sendMessage({
      action: 'SendToAnnotationQueue',
      ...request,
    }));
  }
);

const annotationSlice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {
    addAnnotation: (state, action: PayloadAction<AnnotationTask>) => {
      state.annotations.push(action.payload);
    },
  },
});

export const { addAnnotation } = annotationSlice.actions;

export default annotationSlice.reducer;
