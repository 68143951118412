import * as React from 'react';
import styles from './EmailDocumentPage.module.css';
import { Badge, Button, Divider, Spinner } from '@fluentui/react-components';
import { useState, useEffect } from 'react';
import { MailAddRegular, MailAttachRegular } from '@fluentui/react-icons';
import { LeftArrowIcon } from '../../../assets/LeftArrowIcon';
import { GreenTickIcon } from '../../../assets/GreenTickIcon';
import { useNavigate } from 'react-router-dom';
import { Layout } from './Layout';
import {
  sendAttachmentDocumentRequest,
  setAttachmentSuccess,
} from '../../../store/officeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { AppDispatch } from '../../../store/store';

export const EmailDocumentPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const attachmentSuccess = useSelector(
    (state: RootState) => state.office.attachmentSuccess
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset attachment success state when the component mounts
    dispatch(setAttachmentSuccess(false));
  }, [dispatch]);

  useEffect(() => {
    if (attachmentSuccess) {
      setIsLoading(false);
    }
  }, [attachmentSuccess]);

  const handleSendAttachment = () => {
    setIsLoading(true);
    dispatch(sendAttachmentDocumentRequest());
  };

  return (
    <Layout
      header={
        <div className={styles.toolbarContainer}>
          <div id="nav" className={styles.back} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </div>
        </div>
      }
      footer={<></>}
    >
      <div className={styles.container}>
        <div className={styles.emailContainer}>
          <MailAttachRegular className={styles.emailIcon} />
          <div className={styles.emailText}>Email </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={() => handleSendAttachment()}
            disabled={isLoading}
          >
            <div className={styles.titleContainer}>
              <MailAddRegular className={styles.titleIcon} />
              <div>Send to Outlook</div>
              {!attachmentSuccess && isLoading && (
                <Spinner size="small" className={styles.spinner} />
              )}
              {attachmentSuccess && (
                <GreenTickIcon className={styles.successIcon} />
              )}
            </div>
          </Button>
          <Divider>What happens next?</Divider>
          <div className={styles.furtherInstructionsContainer}>
            <Badge>1</Badge>
            <div className={styles.furtherInstructionsText}>Open Outlook</div>
            <Badge>2</Badge>
            <div className={styles.furtherInstructionsText}>
              Open email and activate <strong>Qanooni</strong> plugin use this
              document as an attachment
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
