import * as React from 'react';

interface GreenTickIconProps {
  className?: string;
}

export const GreenTickIcon: React.FC<GreenTickIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path
        d="M9.00002 16.2L4.80002 12L3.40002 13.4L9.00002 19L21 7.00001L19.6 5.60001L9.00002 16.2Z"
        fill="white"
      />
    </svg>
  );
};