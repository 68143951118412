import { combineReducers } from 'redux';
import socketReducer, { initSocket } from './socketSlice';
import officeReducer, { setIsAnalyzing } from './officeSlice';
import annotationReducer from './annotationSlice';
import draftReducer from './draftSlice';

// hack to get working without end

const rootReducer = combineReducers({
  socket: socketReducer,
  office: officeReducer,
  draft: draftReducer,
  annotation: annotationReducer,
  // Add other reducers as needed
});
export const initApp = () => async dispatch => {
  // production - to work with backend
  dispatch(initSocket());

  // different route to work on screens without having to worry about talking to backend
  // dispatch(setIsAnalyzing(false));
  // Add other initialization logic here
};
export default rootReducer;
