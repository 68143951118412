import * as React from "react";

export const LeftArrowIcon: React.FC = () => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.54038 5.45962C0.28654 5.20578 0.28654 4.79422 0.540381 4.54038L4.67696 0.403805C4.9308 0.149964 5.34235 0.149964 5.59619 0.403805C5.85004 0.657646 5.85004 1.0692 5.59619 1.32304L1.91924 5L5.59619 8.67695C5.85003 8.9308 5.85003 9.34235 5.59619 9.59619C5.34235 9.85003 4.9308 9.85003 4.67695 9.59619L0.54038 5.45962ZM15 5.65L1 5.65L1 4.35L15 4.35L15 5.65Z"
        fill="currentColor"
      />
    </svg>
  );
};
