import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styles from './Clauses.module.css';
import { DocumentSuggestion } from '../../../models/review';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import diff from 'diff-match-patch';

import classNames from 'classnames/bind';
import {
  acceptSuggestion,
  applySuggestion,
  scrollToSuggestion,
  setPreventAnalyzing,
} from '../../../store/officeSlice';
import { ReviewCategory } from './ReviewList';
import { OFFICE_ENCODING_COMMENT } from '../../../helper/OfficeConstants';
import { Button } from '@fluentui/react-components';
import {
  ArrowCounterclockwiseRegular,
  CheckmarkCircleRegular,
  CheckmarkFilled,
  DeleteRegular,
  DocumentEditRegular,
  SkipForwardTabRegular,
  ThumbDislikeRegular,
} from '@fluentui/react-icons';
import { AcceptIcon } from '../../../assets/AcceptIcon';

//refer to the headings for the clauses labels

export const Clauses: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const reviewCategory = state?.reviewCategory as ReviewCategory;

  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  let santizedParagraphs = useSelector(
    (state: RootState) => state.office.sanitizedParagraphs
  ); // do i need this?? probably create a search function

  suggestions = suggestions.filter(x => x.user_suggestion_action !== 'deleted');
  const filteredSuggestions = suggestions.filter(
    x => x.review_category === reviewCategory
  );
  const [currentSuggestion, setCurrentSuggestion] =
    React.useState<DocumentSuggestion>(filteredSuggestions[0]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] =
    React.useState<number>(0);
  const [currentSuggestionPreview, setCurrentSuggestionPreview] =
    React.useState('');

  const [showReplacementControls, setShowReplacementControls] = useState(true);

  // @ts-ignore
  const [setShowReviewButton] = useOutletContext();

  const cx = classNames.bind(styles);
  const className = cx({
    clauseText: true,
    unacceptable: reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: reviewCategory === ReviewCategory.NonStandard,
    acceptable: reviewCategory === ReviewCategory.Acceptable,
    missing: reviewCategory === ReviewCategory.Missing,
  });

  useEffect(() => {
    dispatch(setPreventAnalyzing(true));
    setShowReviewButton(false);
    if (currentSuggestion) {
      dispatch(scrollToSuggestion(currentSuggestion));
    }

    return () => {
      setShowReviewButton(true);
    };
  }, []);

  useEffect(() => {
    //using the  original text find the heading of the clause from the document sections,

    setShowReplacementControls(false);
    if (
      currentSuggestion.highlighted_passage &&
      currentSuggestion.replacement_passage
    ) {
      const dmp = new diff.diff_match_patch();
      const diffs = dmp.diff_main(
        currentSuggestion.highlighted_passage.replace(
          OFFICE_ENCODING_COMMENT,
          ''
        ),
        currentSuggestion.replacement_passage.replace(
          OFFICE_ENCODING_COMMENT,
          ''
        )
      );
      dmp.diff_cleanupSemantic(diffs);
      setCurrentSuggestionPreview(dmp.diff_prettyHtml(diffs));
      setShowReplacementControls(true);
    }
    // added for demo hide replacement for missing clauses, this feature is not yet implemented
    if (reviewCategory === ReviewCategory.Missing) {
      setShowReplacementControls(false);
    }
  }, [currentSuggestion]);

  const navigateToNextSuggestion = () => {
    if (currentSuggestionIndex < filteredSuggestions.length - 1) {
      const suggestion = filteredSuggestions[currentSuggestionIndex + 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  const navigateToPreviousSuggestion = () => {
    if (currentSuggestionIndex > 0) {
      const suggestion = filteredSuggestions[currentSuggestionIndex - 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };

  if (!currentSuggestion) {
    return null;
  }

  const clauseRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.clauseContainer}>
      <div className={styles.clauseHeader}>
        <div className={styles.clauseLabel}>{`${reviewCategory} Clause:`}</div>
        <div className={styles.clauseValue}></div>
        <div className={styles.nav}>
          <div
            ref={clauseRef}
            className={styles.clauseNav}
            onClick={() => {
              navigateToPreviousSuggestion();
            }}
          >
            {currentSuggestionIndex > 0 ? '<' : ' '}
          </div>

          <div className={styles.page}>{`${currentSuggestionIndex + 1} of ${
            filteredSuggestions.length
          }`}</div>
          <div
            className={styles.clauseNav}
            onClick={() => navigateToNextSuggestion()}
          >
            {currentSuggestionIndex < filteredSuggestions.length - 1
              ? '>'
              : ' '}
          </div>
        </div>
      </div>

      {currentSuggestion.highlighted_passage && (
        <div className={className}>
          {currentSuggestion.highlighted_passage.replace(
            OFFICE_ENCODING_COMMENT,
            ''
          )}
        </div>
      )}

      <div className={styles.commentaryHeading}>Commentary:</div>
      <div className={styles.commentary}>{currentSuggestion.note}</div>

      {showReplacementControls && (
        <>
          <div className={styles.commentaryHeading}>
            Reason for replacement:
          </div>
          <div className={styles.commentary}>
            {currentSuggestion.reason_for_replacement_clause}
          </div>
          <div className={styles.recommendedHeading}>
            Recommended suggestion
          </div>

          <div>
            <div>
              {currentSuggestion.user_suggestion_action ? (
                currentSuggestion.user_suggestion_action === 'accepted' ? (
                  <div className={styles.currentSuggestionIndicator}>
                    {currentSuggestion.user_suggestion_action}
                    <AcceptIcon />
                  </div>
                ) : (
                  ''
                )
              ) : null}
            </div>
            {currentSuggestion.replacement_passage.length > 0 ? (
              <>
                {currentSuggestionPreview && (
                  <>
                    <div className={styles.commentaryHeading}>Preview</div>
                    <div
                      className={styles.recommended}
                      dangerouslySetInnerHTML={{
                        __html: currentSuggestionPreview,
                      }}
                    ></div>
                  </>
                )}
              </>
            ) : (
              'None available'
            )}
          </div>

          <div className={styles.clauseToolBar}>
            <DeleteRegular
              style={{ marginLeft: 'auto', height: '1rem', width: '1rem' }}
              onClick={() => {}}
            >
              delete
            </DeleteRegular>
            <DocumentEditRegular
              style={{ marginLeft: 'auto', height: '1rem', width: '1rem' }}
              onClick={() => dispatch(scrollToSuggestion(currentSuggestion))}
            >
              Edit
            </DocumentEditRegular>

            <ArrowCounterclockwiseRegular
              style={{ marginLeft: 'auto', height: '1rem', width: '1rem' }}
            />
            <ThumbDislikeRegular
              style={{ marginLeft: 'auto', width: '20px', height: '20px' }}
            />
          </div>
        </>
      )}

      <div className={styles.clauseToolBar}>
        <Button
          style={{ borderColor: '#08C754' }}
          shape="rounded"
          size="small"
          appearance="outline"
          icon={<CheckmarkCircleRegular />}
          onClick={() => {
            dispatch(acceptSuggestion(currentSuggestion));
            navigateToNextSuggestion();
            clauseRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          Accept
        </Button>

        <Button
          className={styles.replaceButton}
          size="small"
          shape="rounded"
          icon={<SkipForwardTabRegular />}
          onClick={() => {
            navigateToNextSuggestion();
            clauseRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          Ignore
        </Button>
        {showReplacementControls && (
          <Button
            style={{ backgroundColor: '#0387f5' }}
            appearance="primary"
            className={styles.replaceButton}
            size="small"
            shape="rounded"
            icon={<CheckmarkFilled />}
            onClick={() => dispatch(applySuggestion(currentSuggestion))}
          >
            Replace
          </Button>
        )}
      </div>
    </div>
  );
};
