import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Input,
  InputProps,
  mergeClasses,
} from '@fluentui/react-components';
import {
  Attach24Filled,
  Bug24Filled,
  ChatSparkle24Regular,
  ChevronDown16Filled,
  ChevronUp16Filled,
  Dismiss24Regular,
  Send24Filled,
} from '@fluentui/react-icons';

import { MarkdownRenderer } from '../misc/MarkdownRenderer';

import { ChatMessage, MessageRole } from '../../../models/chat';

import { sendChatMessage } from '../../../store/draftSlice';
import { addAttachMessage } from '../../../store/draftSlice';

import { AppDispatch, RootState } from '../../../store/store';

import styles from './ChatContainer.module.css';

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import Spinner from './Spinner'; // Import the Spinner component

export const ChatContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [value, setValue] = React.useState('');
  const [isChatVisible, setIsChatVisible] = React.useState(false);

  const [messagesContainerHeight, setMessagesContainerHeight] = useState('0');
  const inputContainerRef = useRef(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const fileInputRef = useRef(null);

  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Configure pdfjs to not use a worker
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      setLoading(true); // Show spinner when processing starts

      try {
        if (fileExtension === 'txt') {
          // Handle .txt files directly
          const text = await selectedFile.text();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'docx') {
          // Handle .docx files
          const arrayBuffer = await selectedFile.arrayBuffer();
          const zip = new PizZip(arrayBuffer);
          const doc = new Docxtemplater(zip);
          const text = doc.getFullText();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'pdf') {
          // Handle .pdf files
          console.log('Processing PDF file');
          const arrayBuffer = await selectedFile.arrayBuffer();
          console.log('arrayBuffer:', arrayBuffer);

          // Convert ArrayBuffer to Uint8Array
          const uint8Array = new Uint8Array(arrayBuffer);

          // Load PDF with pdfjsLib
          const pdf = await pdfjsLib.getDocument({
            data: uint8Array,
            worker: null,
          }).promise;
          console.log('pdf:', pdf);
          let text = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item: any) => {
              if ('str' in item) {
                text += item.str + ' ';
              }
            });
          }
          console.log('attached text:', text);
          setFileContent(text);
        } else {
          // Handle unsupported file extensions
          console.log(`Unsupported file type: ${fileExtension}`);
          dispatch(
            addAttachMessage({
              id: uuidv4(),
              message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
              role: MessageRole.Attach,
              isChunk: false,
              attached: null,
            })
          );
          return; // Exit the function if the file type is unsupported
        }
        dispatch(
          addAttachMessage({
            id: uuidv4(),
            message: `Attached ${selectedFile.name}`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          })
        );
        console.log('attach message sent');
        setIsChatVisible(true);
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setLoading(false); // Hide spinner when processing completes
      }
    }
  };

  useEffect(() => {
    if (inputContainerRef.current) {
      const height = isChatVisible
        ? `calc(100vh - ${inputContainerRef.current.clientHeight}px)`
        : '0';
      setMessagesContainerHeight(height);
    }
  }, [isChatVisible, inputContainerRef.current]);

  const onChange: InputProps['onChange'] = (_ev, data) => {
    setValue(data.value);
  };

  const onKeyDown: InputProps['onKeyDown'] = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onMessageSend();
    }
  };

  const onMessageSend = () => {
    dispatch(
      sendChatMessage({
        id: uuidv4(),
        message: value,
        role: MessageRole.User,
        isChunk: false,
        attached: fileContent ? fileContent : null,
      })
    );

    setValue('');
    setFileContent(null);
    setIsChatVisible(true);
  };

  return (
    <div className={styles.container}>
      {/* Show spinner when loading */}
      {loading && <Spinner />}

      <div
        className={styles.messagesContainer}
        style={{
          height: messagesContainerHeight,
          display: isChatVisible ? 'flex' : 'none',
        }}
      >
        <div className={styles.messageContainerHeader}>
          <ChatSparkle24Regular className={styles.messageContainerHeaderLogo} />
          <p className={styles.messageContainerHeaderTitle}>Ask QCounsel</p>
          <Dismiss24Regular
            className={styles.messageContainerHeaderIcon}
            onClick={() => setIsChatVisible(false)}
          />
        </div>
        <div className={styles.messageRenderContainer}>
          {chatMessages.map((message: ChatMessage) => {
            const messageClass =
              message.role === 'user'
                ? styles.userMessage
                : message.role === 'attach'
                ? styles.attachMessage
                : styles.assistantMessage;
            return (
              <div
                key={message.id}
                className={mergeClasses(styles.message, messageClass)}
              >
                <MarkdownRenderer markdown={message.message} />
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.messageInputContainer} ref={inputContainerRef}>
        <div className={styles.messageInputTopBar}>
          <ChatSparkle24Regular className={styles.messageContainerIcon} />
          <p className={styles.messagesContainerTitle}>Ask QCounsel </p>
          <div className={styles.spacer}></div>
          <Bug24Filled
            className={styles.messageContainerIcon}
            onClick={() => navigate('/debug')}
          />
          {isChatVisible ? (
            <ChevronDown16Filled
              className={styles.messagesContainerSwitch}
              onClick={() => setIsChatVisible(false)}
            />
          ) : (
            <ChevronUp16Filled
              className={styles.messagesContainerSwitch}
              onClick={() => {
                setIsChatVisible(true);
              }}
            />
          )}
        </div>
        <Input
          className={styles.messageInput}
          placeholder={'Ask QCounsel anything'}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          appearance="outline"
          contentAfter={
            <div className={styles.messageInputButtonsContainer}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                appearance="transparent"
                icon={<Attach24Filled />}
                size="medium"
                onClick={handleUploadClick}
              />
              <Button
                appearance="transparent"
                icon={<Send24Filled />}
                size="medium"
                onClick={onMessageSend}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};
