import React, { useRef } from 'react';

import { Button, makeStyles } from '@fluentui/react-components';

const FileUploader = ({ onFileSelect }) => {
  const fileInputRef = useRef(null);
  const styles = useStyles();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
      <Button
        appearance="transparent"
        className={styles.button}
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
      >
        Browse File
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  button: {
    color: '#5A99E4',
  },
});



export default FileUploader;
