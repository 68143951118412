import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DocumentReviewStarted,
  DocumentSuggestion,
  Response,
  SectionSummaryResponseChunk,
} from '../models/review';
import {
  CreateTemplateResponse,
  DraftDocumentResponse,
  DraftDocumentResponseChunk,
  isCreateTemplateResponse,
  isDeleteTemplateResponse,
  isDraftDocumentResponse,
  isDraftDocumentResponseChunk,
  isListTemplatesResponse,
  isSectionSummaryResponseChunk,
  ListTemplatesResponse,
} from '../models/draft';
import { isChatWordResponse, isChatWordResponseChunk } from '../models/chat';
import { isAnnotationTask } from '../models/annotation';
import { addAnnotation } from './annotationSlice';

import {
  addSuggestion,
  applyGlobalChanges,
  setIsAnalyzing,
  updateDocumentSectionSummary,
  setAttachmentSuccess,
  setDocumentId, // Add this
} from './officeSlice';
import {
  displayDraftChunk,
  displayDraftResponse,
  handleChatMessageResponse,
  handleCreateTemplateResponse,
  handleDeleteTemplateResponse,
  sendTemplateListRequest,
  setIsLoadingListTemplate,
  setTemplateDocuments,
} from './draftSlice';
import { tokenHelper } from '../helper/tokenHelper'; // Import the TokenHelper

interface SocketState {
  connection: WebSocket | null;
  isConnected: boolean;
  socketError?: string;
}

const initialState: SocketState = {
  connection: null,
  isConnected: false,
  socketError: undefined,
};
let connection: WebSocket | undefined = undefined;

const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL;

const getToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(token => {
      if (token) {
        resolve(token);
      } else {
        reject('Failed to get token');
      }
    });
  });
};

export const initSocket = createAsyncThunk(
  'socket/initSocket',
  async (_, { dispatch }) => {
    console.log('Initializing socket');

    const token = await getToken();
    if (connection) {
      connection = undefined;
    }
    console.log('Token:', token);
    if (!connection) {
      const socketUrlWithToken = `${SOCKET_URL}?token=${token}`;
      console.log('WebSocket URL:', socketUrlWithToken);
      connection = new WebSocket(socketUrlWithToken);
    }

    connection.onopen = () => {
      console.log('WebSocket connection opened.');
      dispatch(setConnected(true));

      // get templates for drafting
      dispatch(sendTemplateListRequest());
    };

    connection.onclose = () => {
      console.log('WebSocket connection closed.');
      dispatch(setConnected(false));
    };

    connection.onmessage = (event: MessageEvent<string>) => {
      console.log('message received', event.data);

      const response: Response = JSON.parse(event.data);
      console.log('message received', { response });

      if (response.status == 'error') {
        dispatch(setError(response.errorMessage));
      }

      switch (response.action) {
        case 'OpenWordResponse':
          break;
        case 'DocumentReviewResponse':
          break;
        case 'DocumentSessionStarted':
          // to allow IDE to correctly infer the type of response
          const typedResponse = response as DocumentReviewStarted;
          console.log('DocumentReviewStarted', typedResponse.user_document_id);
          dispatch(setDocumentId(typedResponse.user_document_id));
          // startReview(response.user_document_id);
          dispatch(
            sendMessage({
              action: 'DocumentReviewRequest',
              user_document_id: typedResponse.user_document_id,
            })
          );
          break;
        case 'Suggestion':
          dispatch(addSuggestion(response as DocumentSuggestion));
          break;
        case 'DocumentReviewComplete':
          dispatch(setIsAnalyzing(false));
          break;
        case 'GlobalChangeWordResponse':
          dispatch(applyGlobalChanges(response));
          break;
        case 'SectionSummaryResponse':
          console.log('SectionSummaryResponse', response);
          break;
        case 'UpdateDocumentAttachmentResponse':
          if (response.status === 'success') {
            dispatch(setAttachmentSuccess(true));
          }
        default:
          break;
      }

      // DRAFT LOGIC

      if (isDraftDocumentResponse(response)) {
        const draftDocumentResponse = response as DraftDocumentResponse;
        console.log('DocumentReviewResponse', draftDocumentResponse);
        dispatch(displayDraftResponse(draftDocumentResponse.document_text));
      } else if (isDraftDocumentResponseChunk(response)) {
        const draftDocumentResponseChunk =
          response as DraftDocumentResponseChunk;
        console.log('DocumentReviewResponseChunk', draftDocumentResponseChunk);
        dispatch(displayDraftChunk(draftDocumentResponseChunk.document_text));
      } else if (isListTemplatesResponse(response)) {
        const listTemplateResponse =
          response as ListTemplatesResponse;
        console.log(
          'ListTemplateLibraryResponse',
          listTemplateResponse.documents
        );
        dispatch(setTemplateDocuments(listTemplateResponse.documents));
        dispatch(setIsLoadingListTemplate(false))
      } else if (isCreateTemplateResponse(response)) {
        const createTemplateResponse =
          response as CreateTemplateResponse;
        console.log('CreateTemplateResponse', createTemplateResponse);
        dispatch(handleCreateTemplateResponse(createTemplateResponse));
      } else if (isSectionSummaryResponseChunk(response)) {
        console.log('SectionSummaryResponseChunk', response);
        dispatch(
          updateDocumentSectionSummary(response as SectionSummaryResponseChunk)
        );
      } else if (isDeleteTemplateResponse(response)) {
        console.log('DeleteTemplateResponse', response);
        dispatch(handleDeleteTemplateResponse(response))
      }

      // CHAT LOGIC
      if (isChatWordResponseChunk(response) || isChatWordResponse(response)) {
        dispatch(handleChatMessageResponse(response));
      }

      // DEV DEBUG LOGIC
      if (isAnnotationTask(response)) {
        console.log('AnnotationTask', response);
        dispatch(addAnnotation(response));
      }
    };
  }
);
export const sendMessage = createAsyncThunk<void, any>(
  'socket/sendMessage',
  async (message: any, { dispatch }) => {
    console.log('sending message', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Socket is open. Sending message.');
      dispatch(setError(undefined));
      connection.send(JSON.stringify(message));
    }
  }
);

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      console.log('setting error', action.payload);
      state.socketError = action.payload;
    },
  },
});

export const { setConnected, setError } = socketSlice.actions;

export default socketSlice.reducer;
