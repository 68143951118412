import React from 'react';

import ReactMarkdown from 'react-markdown';

interface MarkdownRendererProps {
    markdown: string;
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ markdown }) => {
    return (
        <ReactMarkdown
            skipHtml={false}
        >
            {markdown}
        </ReactMarkdown>
    );
};